export default {
	bars: require('./bars.svg'),
	home: require('./home.svg'),
	dotcricle: require('./dot-circle.svg'),
	satellitedish: require('./satellite-dish.svg'),
	fingerprint: require('./fingerprint.svg'),
	usercircle: require('./user-circle.svg'),
	bell: require('./bell.svg'),
	thermometer: require('./thermometer.svg'),
	drop: require('./drop.svg'),
	cloudrain: require('./cloud-rain.svg'),
	wind: require('./wind.svg'),
	navigation: require('./navigation.svg'),
	cog: require('./cog.svg'),
	times: require('./times.svg'),
	input: require('./input.svg'),
	swap: require('./swap.svg'),
	history: require('./history.svg'),
	playcircle: require('./play-circle.svg'),
	pausecircle: require('./pause-circle.svg'),
	gpsfixed: require('./gps-fixed.svg'),
	addcircle: require('./add-circle.svg'),
	timeline: require('./timeline.svg'),
	menu: require('./menu.svg'),
	warning: require('./warning.svg'),
	bullets: require('./bullets.svg'),
	arrow: require('./arrow.svg'),
	antenna: require('./antenna.svg'),
	triangle: require('./triangle.svg'),
	bookmark: require('./bookmark.svg'),
	pressure: require('./pressure.svg'),
	sun: require('./sun.svg'),
	chart: require('./chart.svg'),
	toll: require('./toll.svg'),
	eye: require('./eye.svg'),
	star: require('./star.svg'),
	blurcircle: require('./blur-circle.svg'),
	blur: require('./blur.svg'),
	selected: require('./selected.svg'),
	visibilityon: require('./visibility-on.svg'),
	visibilityoff: require('./visibility-off.svg'),
	filter: require('./filter.svg'),
	bubbles: require('./bubbles.svg'),
	build: require('./build.svg'),
	battery50: require('./battery50.svg'),
	layers: require('./layers.svg'),
	people: require('./people.svg'),
	graph: require('./graph.svg'),
	question: require('./question.svg'),
	frown: require('./frown.svg'),
}