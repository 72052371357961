import Store from '@app.util/store'

const triggers = {
	socketReady: (state, socket, dispatch) => {
		dispatch('setSocket', socket)
		socket.on('app.hydrate.success', monitors => dispatch('hydrate', monitors))
		window.setTimeout(()=>{
			socket.emit('app.hydrate')
		}, 500)
	}
}

const setters = {
	status: (state, status) => {
		return {
			...state,
			status : status
		}
	},
	navToggle: (state) => {
		return {...state}
		// return {
		// 	...state,
		// 	navOpen : !state.navOpen
		// }
	},
	navClose: (state) => {
		return {
			...state,
			navOpen : false
		}
	},
	hydrate: (state, data) => {
		return {
			...state,
			monitors : data.monitors,
			weatherStations : data.weatherStations,
			events : data.events,
			hydrated: true
		}
	},
	setSocket: (state, socket) => {
		state.socket = socket
		return {...state}
	}
}

const initialState = {
	navOpen : false,
	alerts : 2,
	events: [],
	monitors : [],
	weatherStations : [],
	status : null,
	hydrated: false,
	socket: null
}

const store = options => Store( 'app', {
	options: options,
	setters: setters,
	triggers: triggers,
	initialState: initialState,
})

export default store