export default class ParserInterface {
	endpoint = null
	lat = 0
	lon = 0
	units = {}

	setCoords = (lat, lon) => {
		this.lat = lat
		this.lon = lon
		this.endpoint = this.buildEndpoint()
	}
	
	setUnits = (units={}) => this.units = units

	fetch = () => fetch(this.endpoint).then(resp => resp.json()).then( data => this.parse(data) )

	parse = data => data

	buildEndpoint = () => this.endpoint
}