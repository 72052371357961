import React, { useState } from 'react'
import { filterReactChildren } from '@app.util'
import './style.scss'

const Component = ({children=[], className, attrs={}, forceOpen=false, persistent, jit}) => {

	let [trigger, options] = filterReactChildren(children, { Trigger: false, Options: false })
	
	// exit grace period - always thinking of user!
	let closeTimer = setTimeout(()=>{},0)
	const handleMouseLeave = () => closeTimer = setTimeout(()=>setOpen(false),300)
	const handleMouseEnter = () => clearTimeout(closeTimer)

	let [open, setOpen] = useState(forceOpen)

	return <span
		data-type='@e.contextmenu'
		className={className||''} 
		data-open={open} 
		onClick={e => {
			e.stopPropagation()
			if(persistent){
				open && e.target.closest("[data-part='trigger']")
					? setOpen(false)
					: setOpen(true)
			}else{
				setOpen(!open)
			}
		}} 
		onMouseEnter={() => handleMouseEnter()}
		onMouseLeave={() => handleMouseLeave()}
		{...attrs} 
	>
		{trigger}
		{jit ? open && options : options}
	</span>
}

Component.Trigger = ({children}) => <span data-part='trigger'>{children}</span>
Component.Options = ({children}) => (
	<span data-part='options'>
		<span data-part='pointer'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 60"><path d="M0,0h100L50,60L0,0z"/></svg></span>
		<span data-part='children'>
			{children}
		</span>
	</span>
)
Component.Options.Group = ({title, children, className}) => <span data-part='options-group' className={className||''}>{title && <p data-part='title'>{title}</p> }{children}</span>
Component.Cta = ({title, onClick=()=>{}}) => <button data-part='cta' onClick={onClick}>{title}</button>
Component.Item = ({children, attrs}) => <span data-part='item' {...attrs}>{children}</span>
Component.Item.Group = ({children}) => <span data-part='item-group'>{children}</span>

Component.Trigger.displayName = 'Trigger';
Component.Options.displayName = 'Options';
Component.Item.displayName = 'Item';

export default Component