import React from 'react'
import moment from 'moment'
import { Icon } from '@app.elements' 
import { Map, Reporting } from '@app.layouts'

export const config = {}

export const routes = {
	map : {
		name : 'Map',
		path : {
			root: '/map',
			param : '/:section?'
		},
		component : <Map/>,
		layout : 'map',
		icon : <Icon.satellitedish/>,
		children : {
			map : {
				name : 'Real time',
				path : {
					root : '/map',
					param : null
				},
				icon : <Icon.antenna/>,
			},
			trending : {
				name : 'Trending',
				path : {
					root : '/map/trending',
					param : null
				},
				icon : <Icon.swap/>,
			},
			analyse : {
				name : 'Analyse',
				path : {
					root : '/map/analyse',
					param : null
				},
				icon : <Icon.chart/>,
			},
			backtrack : {
				name : 'Backtrack',
				path : {
					root : '/map/backtrack',
					param : null
				},
				icon : <Icon.toll/>,
			},
			playback : {
				name : 'Playback',
				path : {
					root : '/map/playback',
					param : null
				},
				icon : <Icon.playcircle/>,
			},
			observations : {
				name : 'Observations',
				path : {
					//root : '/map/observations',
					root : 'https://google.com',
					param : null
				},
				onClick: e => {
					e.stopPropagation()
					e.preventDefault()
					window.open('https://xd.adobe.com/view/31785e14-54d4-40c9-7c06-7f7415e1c630-4749/screen/790a5cac-0f8b-4be9-9f55-40e777c2a963/Web-1920-50?fullscreen', "_blank")
				},
				icon : <Icon.eye/>,
			},
			events : {
				name : 'Events',
				path : {
					root : '/map/events',
					param : null
				},
				icon : <Icon.star/>,
			},
			plume : {
				name : 'Plume Model',
				path : {
					root : '/map/plume-model',
					param : null
				},
				onClick: e => {
					e.stopPropagation()
					e.preventDefault()
					window.open('https://xd.adobe.com/view/31785e14-54d4-40c9-7c06-7f7415e1c630-4749/screen/a9b055f3-e38d-4b11-b036-ac3becc7d4ce/Web-1920-192?fullscreen', "_blank")
				},
				icon : <Icon.toll/>,
			},
			risk : {
				name : 'Risk Forecast',
				path : {
					root : '/map/risk-forecast',
					param : null
				},
				icon : <Icon.warning/>,
			}
		}
	},
	reporting : {
		name : 'Reporting',
		path : {
			root: '/reporting',
			param : null
		},
		component : <Reporting/>,
		layout : 'reporting',
		icon : <Icon.history/>
	},
	insights : {
		name : 'Insights',
		path : {
			root: '/insights',
			param : null
		},
		component : <h1>Insights</h1>,
		layout : 'insights',
		icon : <Icon.bullets/>
	},
	logs : {
		name : 'Logs',
		path : {
			root: '/logs',
			param : null
		},
		component : <h1>Logs</h1>,
		layout : 'logs',
		icon : <Icon.bullets/>
	}
}

// NOTE: 'point' prop is for demo app to get the latest 'x' points
export const dates = [
	{ title: 'Last 3 hours', points: '12', start: () => moment().subtract(3, 'hours'), end: () => moment() },
	{ title: 'Last 6 hours', points: '24', start: () => moment().subtract(6, 'hours'), end: () => moment() },
	{ title: 'Last 12 hours', points: '48', start: () => moment().subtract(12, 'hours'), end: () => moment() },
	{ title: 'Last 24 hours', points: '96', start: () => moment().subtract(24, 'hours'), end: () => moment() },
	{ title: 'Last 36 hours', points: '144', start: () => moment().subtract(36, 'hours'), end: () => moment() },
	{ title: 'Last 48 hours', points: '192', start: () => moment().subtract(48, 'hours'), end: () => moment() },
	{ title: 'Last 96 hours', points: '384', start: () => moment().subtract(48, 'hours'), end: () => moment() },
	{ title: 'Current Week', points: '672', start: () => moment().subtract(48, 'hours'), end: () => moment() },
	{ title: '*Current Month', points: '672', start: () => moment().subtract(48, 'hours'), end: () => moment() },
	{ title: '*Last Week', points: '672', start: () => moment().subtract(48, 'hours'), end: () => moment() },
	{ title: '*Last Month', points: '672', start: () => moment().subtract(48, 'hours'), end: () => moment() },
	{ title: '*Custmom Range', points: '672', start: () => moment().subtract(48, 'hours'), end: () => moment() },
]

export default {
	config: config,
	routes: routes,
	dates: dates
}