import { useEffect, useState } from 'react'

const stores = {}

const storeController = (name, config) => {
	let state = config.initialState || {}
	let triggers = config.triggers || {}
	let setters = config.setters || {}
	let init = typeof config.init === 'function' ? config.init : s => s
	let subscriptions = []
	let initialised = false

	stores[name] = {
		init: (dispatch) => {
			if(initialised !== true){
				let initState = init(state, dispatch)
				state = typeof initState === 'undefined' ? state : initState
				initialised = true
			}
		},
		setState: (action, data, dispatch) => {
			state = setters[action] ? setters[action](state, data, dispatch) : state
			subscriptions.forEach(sub => sub(state))
		},
		trigger: (action, data, dispatch) => {
			triggers[action] && triggers[action](state, data, dispatch)
			//console.log(action, data, dispatch)
		},
		getState(key) {
			return key ? state[key] : state
		},
		subscribe(updater) {
			subscriptions.push(updater)
		},
		unsubscribe(updater) {
			subscriptions = subscriptions.filter(sub => sub !== updater)
		},
	}

	return stores[name] 
}

export const fetchStore = (name, config) => {
	let storeName = name || 'store'
	return !stores[storeName] ? storeController(storeName, config) : stores[storeName]
}

export const useStore = (name, config={}) => {
	let store = fetchStore(name, config)

	const [state, setState] = useState(store.getState())
	
	const set = (action, data) => store.setState(action, data, store.setState)
	const trigger = (action, data) => store.trigger(action, data, store.setState)

	store.init(store.setState)
	
	useEffect(() => {
		store.subscribe(setState)
		return () => store.unsubscribe(setState)
	}, [])

	let options = {
		only: null,
		...config.options
	}

	// configure return values 
	switch (options.only) {
		case 'state':
			return state
		case 'dispatch':
			return {set : set, trigger : trigger}
		default:
			return [state, {set : set, trigger : trigger}]
	}
}

export default (name, config={}) => {
	return useStore(name, config)
}