// eslint-disable-line react-hooks/exhaustive-deps
import React, { useState, useEffect, useMemo } from 'react';
import { get, last, first } from 'lodash'
import Leaflet from 'leaflet'
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Icon, HorseshoeProgress, ContextMenu } from '@app.elements'
import { WeatherWidget, Particulate } from '@app.components'

import './style.scss';

import Marker from '../marker.tsx';
import PollutionRose from './parts/pollutionrose';
import InfluenceArc from './parts/influencearc';

import appStore from '@app/store'
import mapStore from '../../store'

const defaultState = {
	series: [],
	particulate: null,
	current: {
		series: [],
		value: null,
		threshold: [],
		trendrate: 0,
		selected: false
	}
}

const thresholds = [20,40,60,80,100]

const Monitor = ({name, coords, handleAnalysation, weatherStation}) => {
	
	const {state: mapState } = mapStore()
	const context = get(mapState, 'map.context', {})
	const particulate = get(context, 'activeParticulates', new Map()).get(name)

	const [state, setState] = useState(defaultState)
	const [showMenu, setShowMenu] = useState(false)

	// update state when active particulate changes
	useEffect(()=>{
		if(!context) return;

		const currentSeries = get(context, `series.${name}.particulates.${particulate}`, [])
		const currentValue = last(currentSeries)
		const currentThreshold = thresholds.map( (t, i) => currentValue < t ? i+1 : null ).filter(e=>e)[0]

		setState({
			series: get(mapState, `map.current.series.${name}.particulates`, []),
			particulate: particulate,
			current: {
				series: currentSeries,
				value: currentValue,
				threshold: (currentThreshold > 0 ? currentThreshold : -1).toString(),
				trendrate: ((last(currentSeries) - first(currentSeries)) * 0.1 ).toFixed(0),
				selected: get(context, `selected`, new Set()).has(name, particulate),
			},
			marker: get(context, `options.marker`, null)
		})
	}, [
		particulate,
		get(context, `series.${name}.particulates`),
		get(context, `selected`, new Set()).has(name, particulate),
		get(context, `options.marker`, null)
	])

	return useMemo(() => {
		return <Marker 
			position={Leaflet.latLng(coords.lat, coords.lng)} 
			id={name} 
			onMouseOver={e => setShowMenu(true)}
			onMouseOut={e => setShowMenu(false)}
			>
			<div 
				data-type="@e.marker.monitor" 
				data-threshold={state.current.threshold} 
				data-has-particulate={!!state.particulate} 
				data-trendrate={ state.current.trendrate }
				data-selected={ state.current.selected }

				>

				{!state.particulate &&
					<div data-part="select-particulate">
						{Object.keys(get(state, `series`)).map( particulate => {
							return <Particulate
								key={particulate}
								name={particulate} 
								value={last(get(state, `series.${particulate}`))}
								thresholds={thresholds}
								onClick={ e => mapState.map.setParticulate(name, particulate) }
							/>
						})}
					</div>
				}

				{state.particulate &&
					<React.Fragment>
						<svg width="240" height="240" viewBox="0 0 240 240" xmlns="http://www.w3.org/2000/svg" className={'blur'}>
							<radialGradient id={state.current.threshold}> 
								<stop className="color-start" offset="10%" stopColor="rgba(255,0,0,0.3)"/> 
								<stop className="color-end" offset="95%" stopColor="rgba(255,0,0,0)"/> 
							</radialGradient>
							<circle fill={`url(#${state.current.threshold})`} cx="120" cy="120" r="120"/>  
						</svg>

						<div data-part="detail" onClick={ e => mapState.map.toggleSelected(name, particulate) }>
							<div data-part="detail-reading">
								<span data-part="particulate">{state.particulate}</span>
								<span data-part="value">{state.current.value}</span>
								<Sparklines data={state.current.series.slice(1, 10)} min={0} max={100}>
									<SparklinesLine style={{ strokeWidth: 5, stroke: "#40A8DE", fill: "none" }} />
								</Sparklines>
							</div>
							<HorseshoeProgress value={last(state.current.series)} max={75}/>
						</div>

						{showMenu && <div data-part="controls">
							<ContextMenu persistent>
								<ContextMenu.Trigger>
									<Icon.menu/>
								</ContextMenu.Trigger>

								<ContextMenu.Options>
									<ContextMenu.Options.Group title={name}>
										<ContextMenu.Item>
											{Object.keys(get(state, `series`)).map( particulate => {
												return <Particulate
													key={particulate}
													name={particulate} 
													value={last(get(state, `series.${particulate}`))}
													thresholds={thresholds}
													selected={ state.particulate === particulate } 
													onClick={ e => mapState.map.setParticulate(name, particulate) }
												/>
											})}
											
											<Icon.times onClick={ e => mapState.map.setParticulate(name, null) }/>
										</ContextMenu.Item>
									</ContextMenu.Options.Group>

									<ContextMenu.Options.Group title='Odour characterisation' className={'breakdown'}>
										<ContextMenu.Item>
											<Icon.graph/>
										</ContextMenu.Item>
									</ContextMenu.Options.Group>

									<ContextMenu.Options.Group title='weather'>
										<ContextMenu.Item>
											<WeatherWidget.Static 
												id={weatherStation} 
												temperature 
												wind 
												humidity 
												rainfall 
												pressure
											/>
										</ContextMenu.Item>
									</ContextMenu.Options.Group>
								</ContextMenu.Options>
							</ContextMenu>

							<Icon.chart onClick={ e => {
								e.stopPropagation()
								handleAnalysation(new Map().set(name, new Set([state.particulate])))
							}}/>
						</div>}

						{get(state, 'marker') === 'pollution' && <PollutionRose/>}
						{get(state, 'marker') === 'influence' && <InfluenceArc/>}
					</React.Fragment>
				}
			</div>
		</Marker>
	}, [state, showMenu])
};

export default ({handleAnalysation}) => {
	const [appState] = appStore()
	return useMemo(()=>{
		return get(appState, 'monitors', []).map( (data, i) => <Monitor key={i} handleAnalysation={ handleAnalysation } {...data} />)
		//return <Monitor {...get(appState, 'monitors', [])[30]} handleAnalysation={ handleAnalysation } />
	}, get(appState, 'monitors', []))
}