import moment from 'moment'
import uuid from 'uuid'
import App from '@app';
const md5 = require('md5');

export const fetchstatus = {
	INITIALISED: 'initialised',
	FETCHING: 'fetching',
	COMPLETE: 'complete'
}

export const defaultMapState = (defaultParams={}) => ({
	dates: {
		title: 'Real time', 
		points: '10', 
		start: moment().subtract(1, 'hours'), 
		end: moment() 
	},
	series: {},
	layers: {
		backtrack: [],
		observation: [],
		event: [],
		sourceid: [],
		iq: [],
		visible: new Set([])
	},
	options: {
		heatmap: true,
		marker: false,
		trending: false,
		wind: false,
	},
	activeParticulates: new Map(),
	//markerType: null,
	//trending: false,
	//wind: false,
	selected: new MonitorMap(),
	status: fetchstatus.FETCHING,
	...defaultParams
})

const MapInterface = class MapInterfaceState {
	contexts = {}
	currentContext = null
	initialised = false
	updateCallback = ()=>{}
	useLocalState = false

	constructor(name){
		this.name = name||uuid()
		this.init()
		this.initialised = true
	}

	init(){}

	defaultParams(){ return {} }

	// fire the update callback
	update(){
		//localStorage.setItem(`map.${this.name}`, JSON.stringify(this));
		this.updateCallback()
	}

	// todo
	withLocalState(){
		this.useLocalState = true
	}
	
	// set the update callback
	onUpdate(callback){ this.updateCallback = callback }

	hydrate(contextId, dates, callback=()=>{}){
		this.contexts[contextId].status = fetchstatus.FETCHING

		setTimeout( () => {
			App.socket.on(`context.hydrate.success.${contextId}`, data => {
				this.contexts[contextId].series = data.timeseries
				this.contexts[contextId].layers.backtrack = data.backtracks
				this.contexts[contextId].layers.observation = data.observations
				this.contexts[contextId].layers.event = data.events
				this.contexts[contextId].layers.sourceid = data.sourceid
				this.contexts[contextId].layers.iq = data.iq
				//this.contexts[contextId].layers.heatmap = data.heatmaps
				this.contexts[contextId].status = fetchstatus.COMPLETE

				callback(this.contexts[contextId])

				this.update()
			})

			App.socket.emit('context.hydrate', {contextId: contextId, ...dates})
		}, 1000)
	}

	hydrateEvent(contextId, eventId, callback=()=>{}){
		this.contexts[contextId].status = fetchstatus.FETCHING

		setTimeout( () => {	
			App.socket.on(`context.event.hydrate.success.${contextId}`, data => {
				this.contexts[contextId].series = data.timeseries
				this.contexts[contextId].layers.backtrack = data.backtracks
				this.contexts[contextId].layers.observation = data.observations
				this.contexts[contextId].layers.event = data.events
				this.contexts[contextId].layers.sourceid = data.sourceid
				this.contexts[contextId].layers.iq = data.iq
				//this.contexts[contextId].layers.heatmap = data.heatmaps
				this.contexts[contextId].status = fetchstatus.COMPLETE
				
				callback(this.contexts[contextId])

				this.update()
			})
			App.socket.emit('context.event.hydrate', {contextId: contextId, eventId: eventId})
		}, 1000)
	}

	// create a new state context
	createContext(params, hydrate=true){
		params = Object.assign({}, this.defaultParams(), params)
	
		let id = uuid()
		let context = defaultMapState(params);
		
		// set new context
		this.contexts[id] = context
		this.currentContext = id
		
		// fetch context data
		hydrate && this.hydrate(id, context.dates)
		
		// update state
		this.update()

		return id
	}

	removeContext(id){
		delete this.contexts[id];
		this.currentContext = Object.keys(this.contexts)[0]||null
		this.update()
	}
	
	// use a new context by ID
	useContext(id){
		this.currentContext = id||null
		this.update()
	}

	get current(){
		return this.contexts[this.currentContext]||null
	}
	
	// same as above ^^
	get context(){
		return this.contexts[this.currentContext]||null
	}

	setParticulate(name, particulate){

		if(!particulate){
			this.current.activeParticulates.delete(name)
		}else{
			if(name instanceof Map){
				name.forEach( (particulates, monitorId) => {
					particulates.forEach( () => {
						this.current.activeParticulates.set(monitorId, particulate)
					})
				})
			}else{
				this.current.activeParticulates.set(name, particulate)
			}
		}
		this.update()
	}

	setGlobalParticulate(particulate){
		if(particulate === null){
			this.current.activeParticulates = new Map()
		}else{
			Object.keys(this.current.series).forEach( id => {
				this.current.activeParticulates.set(id, particulate)
			})
		}

		this.current.selected.clear();
		
		this.update()
	}

	toggleLayer(name){
		if(this.current.layers.visible.has(name)){
			this.current.layers.visible.delete(name)
		}else{
			this.current.layers.visible.add(name)
		}
		this.update()
	}

	setOption(key, value){
		this.current.options[key] = value
		this.update()
	}

	toggleSelected(id, particulate){
		this.current.selected.toggle(id, particulate)
		this.update()
	}

	unSelectAll(){
		this.current.selected.clear();
		this.update()
	}
}


export default new Proxy(MapInterface, {
	get(target, prop) { 
		return target[prop] === undefined 
			? () => console.log('no method found on state: ' + prop) 
			: target[prop]
	}
})


/*
	Map() of monitors each containing a Set() of particulates
*/
export class MonitorMap {
	
	constructor(init=[]){
		this.items = new Map(init)
		return this
	}

	// if it's on turn it off, if it's off turn it on
	toggle(monitorId, particulate){
		if(particulate){
			let monitor = this.items.get(monitorId)
			if(monitor){
				monitor.has(particulate)
					? monitor.delete(particulate)
					: monitor.add(particulate)

				monitor.size <= 0 && this.items.delete(monitorId)
			}else{
				this.items.set(monitorId, new Set([particulate]))
			}
		}

		return this
	}

	add(monitorId, particulate){
		if(particulate){
			let monitor = this.items.get(monitorId)
			
			if(monitor){
				!monitor.has(particulate) && monitor.add(particulate)
			}else{
				this.items.set(monitorId, new Set([particulate]))
			}
		}

		return this
	}

	clear(){
		this.items.clear()
		return this
	}

	has(monitorId, particulate){
		let monitor = this.items.get(monitorId)
		return monitor
			? monitor.has(particulate)
			: false
	}

	get size(){
		return this.items.size
	}

	get childrensize(){
		let count = 0
		this.items.forEach( monitor => {
			count = count + monitor.size
		})
		return count
	}

	get hash(){
		return md5(this.items.size)
	}
}