import React, { useState, useEffect } from 'react'
import Weather from './weather.js'
import OpenWeatherMaps from './parsers/openWeatherMap.js'
import WeatherChart from './views/chart'
import './style.scss'

let weather = Weather.use({
	interface : OpenWeatherMaps,
	lat : process.env.REACT_APP_MAP_INIT_LAT,
	lon : process.env.REACT_APP_MAP_INIT_LNG,
	units : {
		degrees : 'c',
		wind : 'm/s',
	}
});

export default({app}) => {

	let [forecast, setForecast] = useState(null)
	
	// fetch the forecast on load
	useEffect(() => {
		weather.fetch().then(data => setForecast(data))
		return;
	}, [])

	return <div data-type='@c.weatherforecast'>
		{/*!forecast 
			? <span>Loading...</span> 
			: <WeatherChart data={forecast}/>
		*/}
		<img src="https://cms.envirosuite.com/wp-content/uploads/Screen-Shot-2019-11-20-at-4.38.18-pm.png" alt=""/>
	</div>
}