import ParserInterface from './_interface'
import { vsprintf } from 'sprintf-js'

class Parser extends ParserInterface{
	// todo : move key somewhere else
	// either env vars or create a backend service?
	api_key = '35b3bc98ef63470d20a4106833c8c067';
	endpointString = "http://api.openweathermap.org/data/2.5/forecast?lat=%f&lon=%f&appid=%s";

	// TODO : shape data to common format
	parse = data => {
		let series = {
			time : [],
			temp : [],
			pressure : [],
			humidity : [],
			wind : [],
			rain : [],
		}

		data.list.forEach( item => {
			series.time.push(item.dt)
			series.temp.push(item.main.temp - 273.15)
			series.pressure.push(item.main.pressure)
			series.humidity.push(item.main.humidity)
			series.wind.push(item.wind)
			series.rain.push(item.rain)
		})

		return {
			coords : data.city.coord,
			series : series
		}
	}

	buildEndpoint = () => vsprintf(this.endpointString, [this.lat, this.lon, this.api_key])
}

const instance = new Parser();
Object.seal(instance);

export default instance
