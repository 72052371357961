// eslint-disable-line react-hooks/exhaustive-deps
import { useEffect, useState } from 'react'

const stores = {}

const storeController = (name, config) => {
	let state = config.initialState || {}
	let triggers = config.triggers || {}
	let setters = config.setters || {}
	let init = typeof config.init === 'function' ? config.init : s => s.state
	let subscriptions = []
	let initialised = false
	let onStateUpdate = typeof config.onStateUpdate === 'function' ? config.onStateUpdate : s => s

	let dispatchAction = (actions, action, state, data, set, trigger) => {
		let cb = null

		if(action.includes('.')){
			let split = action.split('.')
			let namespace = actions[split[0]]

			if(namespace && namespace[split[1]]){
				cb = namespace[split[1]]
			}
		}else{
			cb = actions[action]
		}

		let result = cb ? cb({state: state, data: data, set: set, trigger: trigger}) : state
		let newState = result || state

		onStateUpdate({state: newState, name: name})

		return {...newState}
	}

	stores[name] = {
		init: (set, trigger) => {
			if(initialised !== true){
				let initState = init({state: state, set: set, trigger: trigger, name: name, config: config})
				state = typeof initState === 'undefined' ? state : initState
				initialised = true
			}
		},
		set: (action, data, set, trigger) => {
			state = dispatchAction(setters, action, state, data, set, trigger)
			subscriptions.forEach(sub => sub(state))
		},
		trigger: (action, data, set, trigger) => {
			dispatchAction(triggers, action, state, data, set, trigger)
		},
		getState(key) {
			return key ? state[key] : state
		},
		subscribe(updater) {
			subscriptions.push(updater)
		},
		unsubscribe(updater) {
			subscriptions = subscriptions.filter(sub => sub !== updater)
		},
	}

	return stores[name] 
}

export const fetchStore = (name, config) => {
	let storeName = name || 'store'
	return !stores[storeName] ? storeController(storeName, config) : stores[storeName]
}

export const useStore = (name, config={}) => {
	let store = fetchStore(name, config)

	const [state, setState] = useState(store.getState())
	
	const set = (action, data) => store.set(action, data, store.set, store.trigger)
	const trigger = (action, data) => store.trigger(action, data, store.set, store.trigger)

	store.init(set, trigger)
	
	useEffect(() => {
		store.subscribe(setState)
		return () => store.unsubscribe(setState)
	}, [])

	return {
		state: state, 
		set: set, 
		trigger: trigger
	}
}

export default (name, config={}) => {
	return useStore(name, config)
}