import React from 'react'
import { filter } from 'lodash'
import './style.scss'

const Component = ({children=[], className=''}) => {

	// validate children
	children = Array.isArray(children) ? children : [children]
	let crumbs = filter(children, child => {
		let grandchildren = Array.isArray(child) ? child : [child]
		return filter(grandchildren, grandchild => grandchild.type.displayName === 'Crumb' )
	} )

	return <span data-type='@e.breadcrumbs'>
		{crumbs}
	</span>
}

Component.Crumb = ({children}) => <span data-part='crumb'>{children}</span>
Component.Crumb.displayName = 'Crumb';

export default Component