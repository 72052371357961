import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router";
import { get, last, find } from 'lodash'
import pluralize from 'pluralize'
import { Icon, Dot, ContextMenu, Toggle, Button, Nav, Hint } from '@app.elements'
import './style.scss'

import appStore from '@app/store'
import mapStore from '@app.components/map/store'

/// yeah yeah yeah
const particulateNameMap = {
	oi : 'Odour Intensity',
	voc: 'VOC',
	s02: 'S02',
	aqi: 'Air Quality',
	h2s: 'H2S',
}

const initialState = {
	particulates: [],
	selected: {
		items: 0,
		count: 0
	},
	layers: {
		sourceid: false,
		//trending: false,
		impactQuantification: false,
		//count: 0
	}
}

export default withRouter(({history, location}) => {
	
	const [appState] = appStore()
	const {state: mapState } = mapStore()
	const [state, setState] = useState(initialState)
	
	// needs to be abstracted out into common functionality
	useEffect(()=>{
		if(!mapState.map || !mapState.map.current) return

		let current = mapState.map.current

		// get all unique particulates available on map
		//let particulates = Object.values(get(mapState, 'activeStore.state.map.monitors', {}))
		let particulateKeys = [ ...new Set(
			get(appState, 'monitors', [])
			.map( monitor => monitor.particulates.map( p => p.name ))
			.flat()
		)]
		
		// get tally of monitors showing each particulates
		let tally = new Map()
		get(current, 'activeParticulates', new Map()).forEach( name => {
			tally.set(name, (tally.get(name)||0) + 1)
		})

		// get worst particulates
		let worst = {}
		Object.keys(get(current, 'series', {})).forEach( name => {
			let monitor = get(current, 'series', {})[name]
			let monitorConfig = find(appState.monitors, {name: name})
			Object.keys(monitor.particulates).forEach( particulate => {
				let value = last(Object.values(monitor.particulates[particulate]))
				let thresholds = find(monitorConfig.particulates, {name: particulate}, [] ).thresholds
				let threshold = thresholds.map( (t, i) => value < t ? i : null ).filter(e=>e)[0]
				
				if(worst[particulate]){
					if(threshold > worst[particulate]) worst[particulate] = threshold
				}else{
					worst[particulate] = threshold
				}
			})
		})

		setState({
			particulates: [ ...new Set(particulateKeys) ].map( particulate => {
				return {
					key: particulate,
					name: particulateNameMap[particulate]||particulate.name,
					active: tally.get(particulate)||0,
					highest: worst[particulate||0]
				}
			}),
			selected: {
				items: get(current, 'selected').items,
				count: get(current, 'selected').size
			},
			layers: {
				backtrack: get(current, 'layers.visible', new Set()).has('backtrack'),
				observation: get(current, 'layers.visible', new Set()).has('observation'),
				event: get(current, 'layers.visible', new Set()).has('event'),
				sourceid: get(current, 'layers.visible', new Set()).has('sourceid'),
				iq: get(current, 'layers.visible', new Set()).has('iq'),
				wind: get(current, 'layers.visible', new Set()).has('wind'),
				heatmap: get(current, 'layers.visible', new Set()).has('heatmap'),
				//trending: get(current, 'trending', false),
				//impactQuantification: get(current, 'impactQuantification', false),
			}
		})
	},[
		mapState,
		appState
	])

	const handleAnalysation = eventId => {
		switch (location.pathname) {
			case '/map/analyse':
				// if we're already on the analyse screen
				mapState.map.analyseEvent(eventId)
				break;
			default:
				// else change pages
				history.push('/map/analyse', {event: eventId})
				break;
		}
	}
	
	return <div data-part="map-controls">
		<Nav>
			<Nav.Section title="Sensors" controls={<button className={'clear-all'} onClick={ e => mapState.map.setGlobalParticulate(null) }>Clear all</button>}>
				{Object.values(get(state, 'particulates', {})).map( (particulate, i) => {
					return <Nav.Item key={i}>
						<button className={`particulate ${particulate.active > 0 ? '-active' : '-inactive'}`} onClick={ e => mapState.map.setGlobalParticulate(particulate.key)}>
							<span>
								<Dot attrs={{ 'data-threshold-color' : particulate.highest }}/>
								<span data-part="name">{particulate.name}</span>
							</span>
							<span>
								<span data-part="count">{particulate.active||null}</span>
								{particulate.active > 0 ? <Icon.visibilityon/> : <Icon.visibilityoff/>}
							</span>
						</button>
					</Nav.Item>
				})}

				<Nav.Item className={'with-selected'} attrs={{'data-visible': state.selected.count > 0}}>
					<ContextMenu persistent>
		 				<ContextMenu.Trigger>
		 					<Button>
		 						<Icon.selected/>
		 						With Selected
		 						<Dot text={state.selected.count} attrs={{'data-changed': state.selected.count % 2}}/>
		 					</Button>
		 				</ContextMenu.Trigger>
		 				<ContextMenu.Options >
		 					<ContextMenu.Options.Group title="with selected">
		 						<ContextMenu.Item><button onClick={ e => handleAnalysation(state.selected.items) }>Analyse {state.selected.count} {pluralize('item', state.selected.count)}</button></ContextMenu.Item>
		 						<ContextMenu.Item>
		 							<button>Change to</button>
		 							{Object.values(get(state, 'particulates', {}))
	 									.map( (particulate, i) => {
	 										return <Dot 
	 											key={i}
	 											text={particulate.key} 
	 											attrs={{ 'data-threshold-color' : particulate.highest }}
	 											onClick={ () => mapState.map.setParticulate(state.selected.items, particulate.key) }
	 										/>
	 									}
		 							)}
		 						</ContextMenu.Item>
		 						<ContextMenu.Item><button onClick={e => mapState.map.unSelectAll() }>Deselect {state.selected.count} {pluralize('item', state.selected.count)}</button></ContextMenu.Item>
		 					</ContextMenu.Options.Group>
		 				</ContextMenu.Options>
		 			</ContextMenu>
		 		</Nav.Item>		

			</Nav.Section>
		
			<Nav.Section title="Options">
				<Nav.Item>
					<Toggle 
						title='Trending (1hr)' 
						active={get(mapState, 'map.current.options.trending')} 
						onChange={on => mapState.map.setOption('trending', on)} 
						childrenBefore={true}
					>
						<Icon.swap/>
					</Toggle>
				</Nav.Item>

				<Nav.Item >
					<Toggle 
						title='Wind' 
						active={get(mapState, 'map.current.options.wind')} 
						onChange={on => mapState.map.setOption('wind', on)} 
						childrenBefore={true}
					>
						<Icon.wind/>
					</Toggle>
				</Nav.Item>
				
				
				<Nav.Item>
					<Toggle 
						title='Heatmap'
						extra={
							<Hint text={'Show a <strong>heatmap</strong> when zoomed out'}>
								<Icon.question/>
							</Hint>
						}
						active={get(mapState, 'map.current.options.heatmap')} 
						onChange={on => mapState.map.setOption('heatmap', on)} 
						childrenBefore={true}
					>
						<Icon.blur/>
					</Toggle>
				</Nav.Item>
			
			
				<Nav.Item>
					<Toggle.Multi
						title={'Graphic'}
						extra={
							<Hint text={'Switch between <strong>Pollution Rose</strong> or <strong>Influence Arc</strong> when near a marker'}>
								<Icon.question/>
							</Hint>
						}
						items={[
							{
								key: false,
								title: 'off',
								icon: <Icon.visibilityoff/>
							},
							{
								key: 'pollution',
								title: 'Pollution Rose',
								icon: <Icon.dotcricle/>
							},
							{
								key: 'influence',
								title: 'Influence Arcs',
								icon: <Icon.antenna/>
							}
						]}
						//active={get(mapState, 'map.current.options.marker')} 
						onChange={key => mapState.map.setOption('marker', key)}
						childrenBefore={true}
					>
						<Icon.dotcricle/>
					</Toggle.Multi>
				</Nav.Item>
				
				{/*<Nav.Item className={'bookmarks'}>
					<ContextMenu>
						<ContextMenu.Trigger>
							<Button>
								<Icon.bookmark/>
								Bookmarks
							</Button>
						</ContextMenu.Trigger>
						<ContextMenu.Options>
							<ContextMenu.Options.Group title='Saved views'>
								<ContextMenu.Item><button>Worst 20</button></ContextMenu.Item>
								<ContextMenu.Item><button>All Breached</button></ContextMenu.Item>
								<ContextMenu.Item><button>City NO</button></ContextMenu.Item>
							</ContextMenu.Options.Group>

							<ContextMenu.Options.Group title='Saved recipes'>
								<ContextMenu.Item><button>Worst 20</button></ContextMenu.Item>
								<ContextMenu.Item><button>All Breached</button></ContextMenu.Item>
								<ContextMenu.Item><button>City NO</button></ContextMenu.Item>
							</ContextMenu.Options.Group>
						</ContextMenu.Options>
					</ContextMenu>
				</Nav.Item>*/}
			</Nav.Section>

			<Nav.Section title="Current Events">
				<Nav.Item>
					<ContextMenu persistent attrs={{'data-active': Object.values(state.layers).includes(true)}} className={'active-event'}>
						<ContextMenu.Trigger>
							<Button>
								<Icon.warning/>
								EF1234
								{ Object.values(state.layers).includes(true) && <Dot text={Object.values(state.layers).filter(e=>e).length}/>}
							</Button>
						</ContextMenu.Trigger>
						<ContextMenu.Options>
							<ContextMenu.Item>
								<Toggle 
									title='Observations' 
									active={state.layers.observation} 
									onChange={on => mapState.map.toggleLayer('observation')} 
									childrenBefore={true}
								>
									<Icon.eye/>
								</Toggle>
							</ContextMenu.Item>
							<ContextMenu.Item>
								<Toggle 
									title='Backtracks' 
									active={state.layers.backtrack} 
									onChange={on => mapState.map.toggleLayer('backtrack')} 
									childrenBefore={true}
								>
									<Icon.toll/>
								</Toggle>
							</ContextMenu.Item>
							<ContextMenu.Item>
								<Toggle 
									title='Source ID' 
									active={state.layers.sourceid} 
									onChange={on => mapState.map.toggleLayer('sourceid')} 
									childrenBefore={true}
								>
									<Icon.blur/>
								</Toggle>
							</ContextMenu.Item>
							<ContextMenu.Item>
								<Toggle 
									title='Estimated Location' 
									active={state.layers.event} 
									onChange={on => mapState.map.toggleLayer('event')} 
									childrenBefore={true}
								>
									<Icon.warning/>
								</Toggle>
							</ContextMenu.Item>
							<ContextMenu.Item>
								<Toggle 
									title='Impact Quantification' 
									active={state.layers.iq} 
									onChange={on => mapState.map.toggleLayer('iq')} 
									childrenBefore={true}
								>
									<Icon.people/>
								</Toggle>
							</ContextMenu.Item>

							<ContextMenu.Item>
								<Button className={'analyse-event'} onClick={e => handleAnalysation('EF1234')}>Analyse Event</Button>
							</ContextMenu.Item>
							
						</ContextMenu.Options>
					</ContextMenu>
				</Nav.Item>
			</Nav.Section>
		</Nav>
	</div>
})
