import React, { useState } from 'react';
import { WeatherForecast } from '@app.components';
import { Panel, Icon, Toggle } from '@app.elements';
import './style.scss'

//import mapStore from './store'

export default () => {

	let [showForecast, setShowForecast] = useState(false)

	return <Panel open={showForecast} className={'-monitoring'}>
		<Panel.Header>
			<Panel.Header.Group className='_livestats'>
				<span>Situation report:</span>
				<span><span style={{color:'red'}}>3</span> Sensors Offline</span>
				<span><span style={{color:'orange'}}>4</span> Trending Upwards</span>
				<span><span style={{color:'green'}}>186</span> Highest Reading</span>
			</Panel.Header.Group>

			<Panel.Header.Group>
				{/*<Toggle title='Trending' onChange={on => set('trending', on)} childrenBefore={true}><Icon.swap/></Toggle>*/}
				{/*<Panel.Header.Divider/>*/}
				<Toggle title='Weather Forecast' onChange={on => setShowForecast(on)} childrenBefore={true}><Icon.cloudrain/></Toggle>
			</Panel.Header.Group>
		</Panel.Header>

		<Panel.Body>
			{showForecast && <WeatherForecast/>}
		</Panel.Body>
	</Panel>
}