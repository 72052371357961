import React, { useState, useEffect } from 'react'

// static pollution rose for testing
export default () => {
	
	let [visible, setVisible] = useState(false)

	useEffect(() => {
		setVisible(true)
	}, [])

	return <svg data-part="influence-arc" xmlns="http://www.w3.org/2000/svg" width="350" height="350" viewBox="0 0 350 350" data-visible={visible}>
	    <g transform="rotate(-134.35654,232.37401,132.26503)">
	      <path class="inner" fill="rgba(243, 156, 54, 0.3)" d="M 185,63.23 38.49,19.93 a 68.37,68.37 0 0 0 -15.36,43 c 0,25.69 15.36,43.61 15.36,43.61 z"/>
	      <path class="border" fill="rgba(243, 156, 54, 0.4)" d="M 38.83,21.07 181.42,63.23 38.85,105.38 c -2.4,-3 -14.72,-19.79 -14.72,-42.46 a 68.34,68.34 0 0 1 14.7,-41.85 m -0.34,-1.14 a 68.37,68.37 0 0 0 -15.36,43 c 0,25.69 15.36,43.61 15.36,43.61 L 185,63.23 Z"/>
	    </g>
	</svg>
}