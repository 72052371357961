import React from 'react'
import './style.scss'
import IconTemplate from './_template.jsx'
import IconConfig from './assets/_config'

const Icon = ({name, props}) => {
	return <IconTemplate name={name} props={props} icon={IconConfig[name]}/>
}

Object.keys(IconConfig).forEach( name => Icon[name] = props => <Icon name={name} props={props}/> )

export default Icon