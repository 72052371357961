import React from 'react'
import { withRouter } from "react-router";
import moment from 'moment'
import { Breadcrumbs, Link, ContextMenu, Toggle, Icon, HeaderBar, Button, Dot } from '@app.elements'
import './header.scss'

import appStore from '@app/store'
import mapStore from '@app.components/map/store'

export default withRouter(({breadcrumbs, location, history}) => {

	const [state, dispatch] = appStore()
	const { state: mapState, trigger: mapTrigger } = mapStore()

	const handleAnalysation = eventId => {
		switch (location.pathname) {
			case '/map/analyse':
				// if we're already on the analyse screen
				mapState.map.analyseEvent(eventId)
				break;
			default:
				// else change pages
				history.push('/map/analyse', {event: eventId})
				break;
		}
	}
	
	return <HeaderBar className="global-header">
		<HeaderBar.Group>
			<Icon.menu onClick={ e => dispatch.set('navToggle') }/>
			<Breadcrumbs>
				<Breadcrumbs.Crumb><Link to={'/'}><Icon.home/></Link></Breadcrumbs.Crumb>
				{breadcrumbs.map( (crumb, i) => <Breadcrumbs.Crumb key={i}><Link to={`/${crumb}`}>{crumb}</Link></Breadcrumbs.Crumb> )}
			</Breadcrumbs>
		</HeaderBar.Group>

		<HeaderBar.Group>
			<h1 className="title">{process.env.REACT_APP_SITENAME}</h1>
		</HeaderBar.Group>

		<HeaderBar.Group>
			<ContextMenu className={'events'}>
				<ContextMenu.Trigger>
					<Icon.warning attrs={{'data-has-event': state.events.length > 0}}/>
					{state.events.length > 0 && <Dot text={state.events.length}/>}
				</ContextMenu.Trigger>
				<ContextMenu.Options>
					<ContextMenu.Options.Group title="Recent Events">
						{state.events.map( (event, i) => {
							return <ContextMenu.Item key={i} attrs={{'data-current': !event.dates.end}}>
								<span data-part="event-title">
									{!event.dates.end && <Icon.warning/>}
									#{event.id}&nbsp;|&nbsp;
									{event.classification} Event&nbsp;|&nbsp;
									<button onClick={ e => {
										mapTrigger('flyTo', {
											lat: event.location.lat, 
											lng: event.location.lng,
											zoom: 15
										})
									}}>
										{event.suburb}
									</button> &nbsp;|&nbsp;
									2 hours ago &nbsp;|&nbsp; 
									{event.dates.end === null ? 'IN PROGRESS' : 'FINISHED'}
									{/*#12345 | Odour Event | Eagle Farm | -2 hours | IN PROGRESS*/}
								</span>
								<span data-part="event-text">
									<p>
										<strong>{moment(event.dates.start).from(moment())}</strong>
										(06:00) an {event.classification} event started near&nbsp;
										<button onClick={ e => {
											mapTrigger('flyTo', {
												lat: event.location.lat, 
												lng: event.location.lng,
												zoom: 15
											})
										}}>
											{event.suburb} ({event.location.lat}, {event.location.lng})
										</button>
									</p>
									{event.summary.map( (item, i) => <p key={i} dangerouslySetInnerHTML={{__html: item}}/>)}
									<Button onClick={ e => handleAnalysation(event.id) }>Analyse Event</Button>
								</span>
							</ContextMenu.Item>
						})}
					</ContextMenu.Options.Group>
				</ContextMenu.Options>
			</ContextMenu>


			{/*<ContextMenu 
				trigger={<Icon.usercircle/>} 
				title={'Current alerts'} 
				openOnClick={true} 
				position={'bottom-left'}
			>
				<div><strong>Some alert</strong> is happening right now</div>
				<div><strong>Some alert</strong> is happening right now</div>
			</ContextMenu>*/}

			<ContextMenu trigger={<Icon.usercircle/>} title={'User Options'} openOnClick={true} position={'bottom-left'}>
				<Link to="/"><Icon.home/> Profile</Link>
				<Link to="/"><Icon.home/> Settings</Link>
				<Link to="/"><Icon.home/> Something</Link>
				<Link to="/"><Icon.home/> Logout</Link>
				
				<Toggle title='Toggle something' onChange={ on => console.log(on) }/>
				<Toggle title='on off' onChange={ on => console.log(on) }/>
				<Toggle title='switch me' onChange={ on => console.log(on) }/>
				<Toggle title='click me!' onChange={ on => console.log(on) }/>
			</ContextMenu>

		</HeaderBar.Group>
	</HeaderBar>
})
