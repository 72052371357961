import React from 'react';
import { Nav, Icon } from '@app.elements' 
import App  from '@app'
import './nav.scss'

import appStore from '@app/store'

let situation = {routes: ['map']}
let reporting = {routes: ['reporting', 'insights', 'logs']}

export default ({open}) => {

	const [state, dispatch] = appStore()

	let timer = setTimeout(null,0)
	const handleClose = () => timer = setTimeout( () => dispatch.set('navClose'), 200)
	
	return <div data-part="global-nav" onMouseEnter={ e => clearTimeout(timer)} onMouseLeave={ e => handleClose()} onClick={ e => dispatch.set('navClose') } data-open={state.navOpen}>
		<Nav>
			<Nav.Header>
				<Icon.arrow onClick={ e => dispatch.set('navClose') }/>
				<span data-part="version">Envirosuite Smart Cities<br/>v0.0.1 build 12345 @ 26 Feb 2019</span>
			</Nav.Header>

			<Nav.Section>{App.links(situation)}</Nav.Section>
			<Nav.Section title={'Reporting'}>{App.links(reporting)}</Nav.Section>
		</Nav>
	</div>
}