import React from 'react'
import './style.scss'

export default ({title, text, children, style={}}) => {
	return <span data-type='@e.hint' style={style}>
		{(title || text) && 
			<span data-part="popup">
				{title && <span data-part="popup-title">{title}</span>}
				{text && <span data-part="popup-text" dangerouslySetInnerHTML={{__html: text}}/>}
			</span>
		}
		{children}
	</span>
}