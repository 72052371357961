import React from 'react'
import moment from 'moment'
import './style.scss'

export default ({timestamp=null, format='DD.MM.YY kk:mm', isUnix, prefix, suffix, className=''}) => {
	let parsed = isUnix 
		? moment.unix(timestamp) 
		: moment(timestamp)

	let formatted = typeof format === 'object'
		? Object.keys(format).map( key => <span key={key} data-part="part" className={key}>{parsed.format(format[key])}</span>)
		: parsed.format(format)

	return <time data-type="@e.date" className={className} dateTime={parsed.format("YYYY-MM-DDTHH:mm:ssZ")}>
		{prefix && <span data-part="prefix">{prefix}</span>}
		<span data-part="value">{formatted}</span>
		{suffix && <span data-part="suffix">{suffix}</span>}
	</time>
} 