import React from 'react'
import { find, get } from 'lodash'
import './style.scss'

const Component = ({children=[], className='', open=false}) => {

	children = Array.isArray(children) ? children : [children]
	
	let header = find(children, child => get(child, 'type.displayName') === 'Header' )
	let body = find(children, child => get(child, 'type.displayName') === 'Body' )

	return <div data-type="@e.panel" className={className||''} data-open={open}>
		{header}
		{body}
	</div>
}


Component.Header = ({children}) => <div data-part="header">{children}</div>
Component.Header.Group = ({children, className=''}) => <div data-part="group" className={className}>{children}</div> 
Component.Header.Divider = () => <span data-part="divider"/> 	
Component.Body = ({children}) => <div data-part="body">{children}</div> 

Component.Header.displayName = 'Header';
Component.Body.displayName = 'Body';

export default Component