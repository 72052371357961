import React from 'react'
import { NavLink } from "react-router-dom";
import './style.scss'

const Component = ({to, handleClick, children}) => {
	children = Array.isArray(children)
		? children.map( child => typeof child === 'string' ? <span key={child} data-part='text'>{child}</span> : child )
		: children

	return <NavLink data-type='@e.link' to={to} onClick={ e => handleClick && handleClick(e) } activeClassName={'--current'} exact>{children}</NavLink>
}

Component.displayName = 'Link'

export default Component