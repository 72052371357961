// eslint-disable-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react'
import { get, find } from 'lodash'
import { Icon, ContextMenu, Toggle, Select } from '@app.elements' 
import { useLocalStorageState } from '@app.util/hooks'
import './style.scss'

import appStore from '@app/store'

let defaultState = {
	temperature: true, 
	wind: true,
	humidity: false,
	dew_point: false,
	rain: false,
	pressure: false
}

const WeatherGroup = ({type, value, children}) => {
	return <span data-part='conditions-group' data-type={type}>
		{children}
		<span data-part='value'>{value}</span>
	</span>
} 

WeatherGroup.Temp = ({temp}) => <WeatherGroup type="temperature" value={`${temp}°c`}><Icon.cloudrain/></WeatherGroup>
WeatherGroup.Wind = ({speed, degrees}) => <WeatherGroup type="wind" value={`${speed}kt`}><Icon.navigation style={{'transform': `rotate(${(-180)+degrees}deg)`}}/></WeatherGroup>
WeatherGroup.Humidity = ({humidity}) => <WeatherGroup type="humidity" value={`${humidity}%`}><Icon.drop/></WeatherGroup>
WeatherGroup.Dewpoint = ({dewpoint}) => <WeatherGroup type="dewpoint" value={`${dewpoint}°c`}><Icon.drop/></WeatherGroup>
WeatherGroup.Rainfall = ({rainfall}) => <WeatherGroup type="rainfall" value={`${rainfall}mm`}><Icon.drop/></WeatherGroup>
WeatherGroup.Pressure = ({pressure}) => <WeatherGroup type="pressure" value={`${pressure}mm`}><Icon.pressure/></WeatherGroup>


const WeatherWidget = () => {
	
	const [store] = appStore()
	const [state, setState] = useLocalStorageState('weatherstation', defaultState)
	const [weatherStation, setWeatherStation] = useState({})

	// update the weather station we're using
	useEffect(()=>{
		let stationId = get(state, 'weatherstation', get(store, 'weatherStations')[0]._id)
		let weather = find(get(store, 'weatherStations'), {_id: stationId});
		setWeatherStation(weather)
	}, [state.weatherstation])
	
	// toggle parameter on or off
	const toggleParameter = (name, on) => {
		state[name] = on;
		setState(state)
	}

	return <div data-type='@c.weatherwidget'>
		<span data-part='conditions'>
			{state.temperature && <WeatherGroup.Temp temp={get(weatherStation, 'values.temperature')}/>}
			{state.wind && <WeatherGroup.Wind {...get(weatherStation, 'values.wind')} />}
			{state.humidity && <WeatherGroup.Humidity humidity={get(weatherStation, 'values.humidity')} />}
			{state.dew_point && <WeatherGroup.Dewpoint dewpoint={get(weatherStation, 'values.dew_point')} />}
			{state.rainfall && <WeatherGroup.Rainfall rainfall={get(weatherStation, 'values.rainfall')} />}
			{state.pressure && <WeatherGroup.Pressure pressure={get(weatherStation, 'values.pressure')} />}
		</span>

		<ContextMenu persistent>
			<ContextMenu.Trigger><Icon.cog/></ContextMenu.Trigger>
			<ContextMenu.Options>
				<ContextMenu.Options.Group>
					
					<ContextMenu.Item>
						<ContextMenu.Item.Group><Icon.sun/>Temperature</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Icon.drop/>{get(weatherStation, 'values.temperature')}°c </ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Toggle active={state.temperature} onChange={ on => toggleParameter('temperature', on) }/></ContextMenu.Item.Group>
					</ContextMenu.Item>

					<ContextMenu.Item>
						<ContextMenu.Item.Group><Icon.wind/>Wind</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Icon.navigation style={{'transform': `rotate(${(-180)+get(weatherStation, 'values.wind.degrees')}deg)`}}/>{get(weatherStation, 'values.wind.speed')}kt</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Toggle active={state.wind} onChange={ on => toggleParameter('wind', on) }/></ContextMenu.Item.Group>
					</ContextMenu.Item>

					<ContextMenu.Item>
						<ContextMenu.Item.Group><Icon.drop/>Humidity</ContextMenu.Item.Group>
						<ContextMenu.Item.Group>{get(weatherStation, 'values.humidity')}%</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Toggle active={state.humidity} onChange={ on => toggleParameter('humidity', on) }/></ContextMenu.Item.Group>
					</ContextMenu.Item>

					<ContextMenu.Item>
						<ContextMenu.Item.Group><Icon.drop/>Dew Point</ContextMenu.Item.Group>
						<ContextMenu.Item.Group>11°c</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Toggle active={state.dew_point} onChange={ on => toggleParameter('dew_point', on) }/></ContextMenu.Item.Group>
					</ContextMenu.Item>

					<ContextMenu.Item>
						<ContextMenu.Item.Group><Icon.drop/>Rain</ContextMenu.Item.Group>
						<ContextMenu.Item.Group>{get(weatherStation, 'values.rainfall')}mm</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Toggle active={state.rainfall} onChange={ on => toggleParameter('rainfall', on) }/></ContextMenu.Item.Group>
					</ContextMenu.Item>

					<ContextMenu.Item>
						<ContextMenu.Item.Group><Icon.drop/>Pressure</ContextMenu.Item.Group>
						<ContextMenu.Item.Group>{get(weatherStation, 'values.pressure')}mb</ContextMenu.Item.Group>
						<ContextMenu.Item.Group><Toggle active={state.pressure} onChange={ on => toggleParameter('pressure', on) }/></ContextMenu.Item.Group>
					</ContextMenu.Item>
				</ContextMenu.Options.Group>

				<ContextMenu.Options.Group>
					<Toggle title='Show Wind Vectors' active={state.wind_vectors} onChange={ on => toggleParameter('wind_vectors', on) }/>
				</ContextMenu.Options.Group>
				<ContextMenu.Options.Group>
					<Select title='Weather From' selected={state.weatherstation} onChange={ id => toggleParameter('weatherstation', id) } value={state.weatherstation}>
						{get(store, 'weatherStations', []).map( (station, i) => {
							return <Select.Option key={i} label={station.name} value={station._id}/>
						})}
					</Select>
				</ContextMenu.Options.Group>
			</ContextMenu.Options>
		</ContextMenu>
	</div>
}

WeatherWidget.Static = ({id, temperature, wind, humidity, dew_point, rainfall, pressure}) => {

	const [store] = appStore()
	let weather = get(find(store.weatherStations, {_id : id}), 'values', {})

	return <div data-type='@c.weatherwidget.static'>
		{temperature && weather.temperature && <WeatherGroup.Temp temp={get(weather, 'temperature')}/>}
		{wind && weather.wind && <WeatherGroup.Wind {...get(weather, 'wind')} />}
		{humidity && weather.humidity && <WeatherGroup.Humidity humidity={get(weather, 'humidity')} />}
		{dew_point && weather.dew_point && <WeatherGroup.Dewpoint dewpoint={get(weather, 'dew_point')} />}
		{rainfall && weather.rainfall && <WeatherGroup.Rainfall rainfall={get(weather, 'rainfall')} />}
		{pressure && weather.pressure && <WeatherGroup.Pressure pressure={get(weather, 'pressure')} />}
	</div>
}

export default WeatherWidget