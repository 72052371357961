import React, { useState, useEffect } from 'react';
import './style.scss'

const Toggle = React.memo( ({title, children, extra, active=null, onChange, childrenBefore=false}) => {

	let [isActive, setActive] = useState(active)

	useEffect(() => {
		active !== null && setActive(active)
	}, [active])

	let handleClick = () => {
		active === null && setActive(!isActive)
		onChange && onChange(!isActive)
	}

	return <span data-type='@e.toggle' data-active={isActive} onClick={ handleClick }>
		{childrenBefore && children}
		{title && <span data-part='title'>{title}</span>}
		{extra}
		<span data-part='switch'>
			<span data-part='indicator'/>
		</span>
		{!childrenBefore && children}
	</span>
})

const Multi = ({title, items=[], children, extra, active=0, onChange, childrenBefore=false}) => {
	
	let [activeIndex, setActiveIndex] = useState(active)

	useEffect(() => {
		setActiveIndex(activeIndex)
	}, [active])

	let handleClick = i => {
		setActiveIndex(i)
		onChange && onChange(items[i].key)
	}

	return <span data-type='@e.toggle' className={'-multi'} data-active={activeIndex !== 0}>
		{childrenBefore && children}
		{title && <span data-part='title'>{title}</span>}
		{extra}
		<span data-part='multiswitch'>
			{items.map((item, i) => <span data-part='multiswitch-item' title={item.title} onClick={e => handleClick(i)} data-active={activeIndex === i}>{item.icon}</span>)}
			<span data-part='indicator' style={{left: `${100 / items.length * activeIndex}%`}}/>
		</span>
		{!childrenBefore && children}
	</span>
}

Toggle.Multi = Multi


export default Toggle