import React from 'react'
import { filterReactChildren } from '@app.util'
import './style.scss'

const Component = ({children}) => <nav data-type='@e.nav'>{filterReactChildren(children, { Header: false, Section: true })}</nav>

// partials
Component.Header = ({children}) => <span data-part='header'>{children}</span>
Component.Section = ({title, children, allow={}, controls, attrs={}}) => {
	
	children = Array.isArray(children) ? children : [children]
	children = children.map( (child, i) => {
		child = Array.isArray(child) ? child : [child]

		return child.map( (c, i) => {
			return c.type.displayName === 'Item'
				? c
				: <Component.Item key={i}>{c}</Component.Item>
		})
	})

	return <span data-part='section' data-name={title && title.sluggify()} {...attrs}>{title && <label data-part='title'>{title}</label>}{children}{controls && <span data-part='controls'>{controls}</span>}</span>
}
Component.Item = ({children, className, attrs={}}) => <span data-part='item' className={className} {...attrs}>{children}</span>
Component.Button = ({children, className, onClick=()=>{}, attrs={}}) => {
	children = Array.isArray(children)
		? children.map( child => typeof child === 'string' ? <span key={child} className="_text">{child}</span> : child )
		: children

	return <button data-part='button' className={className} {...attrs} onClick={onClick}>{children}</button>
}

// names
Component.Header.displayName = 'Header';
Component.Section.displayName = 'Section';
Component.Item.displayName = 'Item';
Component.Button.displayName = 'Button';

export default Component