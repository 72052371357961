// eslint-disable-line react-hooks/exhaustive-deps
import React, { useEffect, useRef, useState }from 'react'
import { get } from 'lodash'
import { withRouter } from "react-router";

// UI parts
import MapControl from './partials/controls'
import MapInterfaceContainer from './partials/interfacecontainer'
import MapNav from './partials/nav'
import MapDate from './partials/date'
import 'leaflet/dist/leaflet.css'

/*
	analytical interfaces + stores
*/
// monitoring
import MonitoringContext from './contexts/monitor/'
import MonitoringState from './contexts/monitor/state'
// comparison
import ComparisonContext from './contexts/compare/'
import ComparisonState from './contexts/compare/state'

// stores
import mapStore from './store'

// leaflet/google maps
import { Map as ReactLeafletMap } from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google';

// map layers
import Menu from './layers/menu/';
import ObservationFactory from './layers/observation'
import BacktrackFactory from './layers/backtrack'
import EventFactory from './layers/event'
import SourceidFactory from './layers/sourceid'
import ImpactQuantificationFactory from './layers/impactquantification'
import MonitorFactory from './layers/monitor'
import WindFactory from './layers/wind'

import './style.scss'

// zoom values at which markers transform
let zoomLevels = [13, 14, 15, 16, 17]

export default withRouter(({location, history}) => {
	const {state, set} = mapStore()

	const mapRef = useRef()
	const interfacePanelRef = useRef()
	const [contextMenu, setContextMenu] = useState(null)
	const [zoom, setZoom] = useState(state.params.map.zoom)

	// provide a zoom level attribute for the map container
	// value is a series of poles ('|') depending on zoom level 
	// eg: ||| would indicate zoom level 3
	// can be used for progressing styling with the [data-zoom-level*='|||'] attribute 
	const handleZoomChange = zoom => {
		let level = zoomLevels.findIndex( level => zoom <= level );
		setZoom(Array( level >= 0 ? level+1 : zoomLevels.length+1).fill('|').join(''))
	}
	
	// handle with selected menu changes
	const analyseMonitors = data => {
		switch (location.pathname) {
			case '/map/analyse':
				// if we're already on the analyse screen
				state.map.analyse(data)
				break;
			default:
				// else change pages
				history.push('/map/analyse', {selected: data})
				break;
		}
	}

	// handle with selected menu changes
	const analyseEvent = id => {
		switch (location.pathname) {
			case '/map/analyse':
				// if we're already on the analyse screen
				state.map.analyseEvent(id)
				break;
			default:
				// else change pages
				history.push('/map/analyse', {event: id})
				break;
		}
	}

	// display panel based on route
	useEffect(()=>{
		switch (location.pathname) {
			case '/map':
				interfacePanelRef.current.useInterface(<MonitoringContext/>, MonitoringState )
				break;
			case '/map/analyse':
				let selected = get(location, 'state.selected')
				let event = get(location, 'state.event')
				history.replace({...location, state: null})
				interfacePanelRef.current.useInterface(<ComparisonContext selected={selected} event={event}/>, ComparisonState )
				break;
			default:
				interfacePanelRef.current.useInterface(null)
				break;
		}
	}, [location.pathname])

	// drop the map ref into the map state on load so we can use elsewhere
	useEffect(()=>{
		set('mapRef', get(mapRef.current, 'leafletElement'))
	}, [mapRef.current])

	return <section
		data-type="@c.map" 
		data-zoom-level={zoom} 
		data-heatmap={get(state, 'map.context.options.heatmap', false)} 
		data-trending={get(state, 'map.context.options.trending', false)}
		data-marker={get(state, 'map.context.options.marker', false)}
		>
		
		<MapControl handleAnalysation={ analyseMonitors } />
		
		<ReactLeafletMap 
			center={[state.params.map.lat, state.params.map.lng]}
			minZoom={12.2}
			maxBounds={state.params.map.bounds} 
			zoom={state.params.map.zoom} 
			layerControl={false}
			//zoomSnap={0.5}
			//zoomDelta={0.5}
			whenReady={e => handleZoomChange(e.target._zoom)}
			onMoveEnd={e => handleZoomChange(e.target._zoom)}
			onMoveStart={() => setContextMenu(null)}
			//onContextMenu={ e => setContextMenu(e.latlng) }
			//onClick={ e => console.log(e.latlng.lat.toFixed(4), e.latlng.lng.toFixed(4)) }
			//onClick={ e => console.log(e.target.getBounds()) }
			ref={mapRef}
			>

			<GoogleLayer googlekey={state.params.map.apikey} maptype={state.params.map.type} styles={state.params.map.theme} />
			
			<MonitorFactory handleAnalysation={ analyseMonitors }/>
			<SourceidFactory/>
			<EventFactory handleAnalysation={ analyseEvent }/>
			<BacktrackFactory/>
			<ObservationFactory/>
			<ImpactQuantificationFactory/>
			{contextMenu && <Menu latlng={contextMenu} onClose={() => setContextMenu(null)}/>}
			<WindFactory/>

		</ReactLeafletMap>
		
		<div data-part="map-state">
			<MapInterfaceContainer ref={interfacePanelRef}>
				<MapDate/>
			</MapInterfaceContainer>
			<MapNav/>
		</div>	
	</section>
})