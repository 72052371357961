// eslint-disable-line react-hooks/exhaustive-deps
import React from 'react';
import moment from 'moment'
import { get } from 'lodash'
import Leaflet from 'leaflet'
import Marker from '../marker.tsx';
import { Icon, ContextMenu } from '@app.elements'
import './style.scss';

import mapStore from '../../store'

const Event = ({id, classification, dates, location, suburb, summary, handleAnalysation=()=>{}}) => {
	return <Marker position={Leaflet.latLng(location.lat, location.lng)} id={id}>
		<div data-type="@e.marker.event">
			<ContextMenu>
				<ContextMenu.Trigger>
					<Icon.warning/>
				</ContextMenu.Trigger>
				<ContextMenu.Options>
					<ContextMenu.Item>
						<span data-part="event-title">
							{!dates.end && <Icon.warning/>} #{id} | {classification} Event | {suburb} | 2 hours ago &nbsp;|&nbsp;  {dates.end === null ? 'IN PROGRESS' : 'FINISHED'}
						</span>
						<span data-part="event-text">
							<p>
								<strong>{moment(dates.start).from(moment())}</strong> (06:00) an {classification} event started near {suburb} ({location.lat}, {location.lng})
							</p>
							{summary.map( (item, i) => <p key={i} dangerouslySetInnerHTML={{__html: item}}/>)}
							<button onClick={ e => handleAnalysation(id) }>Analyse Event</button>
						</span>
					</ContextMenu.Item>
				</ContextMenu.Options>
			</ContextMenu>

			
		</div>
	</Marker>
};

export default ({handleAnalysation}) => {
	const { state } = mapStore()
	let visible = get(state, 'map.current.layers.visible', new Set()).has('event')
	return visible
		? get(state, 'map.current.layers.event', []).map( (data, i) => <Event key={i} {...data} handleAnalysation={ handleAnalysation }/>)
		: null
}

// classification: "Waste Water"
// datapointcount: 144
// dates: {start: "2019-03-22T05:59:54.355Z", end: "2019-03-23T05:59:54.356Z"}
// id: "MU1234"
// location: {lat: -27.433262, lng: 153.125092}
// suburb: "Murarrie"
// summary: (4) ["Odo