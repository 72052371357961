import { useState } from 'react'


export const useReducer = (reducer, initialState) => {
	const [state, setState] = useState(initialState);

	function dispatch(action) {
		const nextState = reducer(state, action);
		setState(nextState);
	}

	return [state, dispatch];
}

// state with localstorage enabled
// todo : check for corrupt localstorage and remove 
export const useLocalStorageState = (key, initialValue) => {
	
	const [item, setValue] = useState(() => {
		let value = JSON.parse(localStorage.getItem(key)) || initialValue;
		localStorage.setItem(key, JSON.stringify(value));
		return value;
	});

	const setItem = (newValue) => {
		setValue(newValue);
		window.localStorage.setItem(key, JSON.stringify(newValue));
	};

	return [item, setItem];
}

export const useParticulateMap = () => {
	
	const [items, setItems] = useState(new Map([]))

	const toggleItem = (id, val) => {
		    
		if(items.has(id)){
			//let vals = items.get(id)

			items.get(id).has(val)
				? items.get(id).delete(val)
				: items.get(id).add(val)


			items.get(id).size <= 0 && items.delete(id)
		}else{
			items.set(id, new Set([val]))
		}

		setItems(items)
	};

	return [items, toggleItem];
}