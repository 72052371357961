import React from 'react'
import { filter } from 'lodash'
import './style.scss'

const Button = ({className, children, onClick=()=>{}, attrs={}}) => {

	children = Array.isArray(children)
		? children.map( child => typeof child === 'string' ? <span key={child} className="_text">{child}</span> : child )
		: children

	return <button data-type="@e.button" className={className} onClick={onClick} {...attrs}>{children}</button>
}

Button.displayName = 'Button';

Button.Group = ({className='', children}) => {
	children = Array.isArray(children) ? children : [children]
	let Buttons = filter(children, child => child.type.displayName === 'Button' )

	return <div data-type="@e.button-group" className={className}>{Buttons}</div>
}

export default Button