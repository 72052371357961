import React, { useEffect, useState } from 'react';
import Marker from '../marker.tsx';
import { Icon, Button } from '@app.elements'
import './style.scss';

export default ({latlng, onClose=()=>{}}) => {
	
	const [open, setOpen] = useState(false)
	
	useEffect(()=>{
		setOpen(true)
	}, [])

	const close = e => {
		e.stopPropagation()
		setOpen(false)
		setTimeout(() => onClose(), 500)
	}

	return <Marker position={latlng} id={'contextmenu'}>
		<div data-type="@e.marker.menu" data-open={open}>
			<Icon.times onClick={ e => close(e) }/>
			<div data-part='nav'>
				<Button>Run Backtrack</Button>
				<Button>Select all monitors within __km</Button>
				<Button>Particulate info</Button>
				<Button>Weather</Button>
			</div>
		</div>
	</Marker>
};
