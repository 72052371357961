import React from 'react';
import { get, find } from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import appStore from '@app/store'
import mapStore from '@app.components/map/store'

import './style.scss'

export default ({items}) => {
	const [appState] = appStore()
	const { state } = mapStore()
	const context = state.map.current

	let series = [];
	items.forEach( (particulates, name) => {
		particulates.forEach( particulate => {
			series.push({
				name: find(appState.monitors, {name: name}).name,
				data: get(context, `series.${name}.particulates.${particulate}`, [])
			})
		})
	})

	let chartOptions = {
		chart: {
			height: 150,
			backgroundColor: 'transparent',
			type: 'spline',
			margin: 0
		},
		title: { text: '' },
		subtitle: { text: '' },
		credits: { enabled: false },
		pane: { size: '100%' },
		legend:{ enabled:false },
		series: series,
		xAxis: {
			min: 0,
			maxPadding: 0,
			lineWidth: 0,
			gridLineWidth: 0,
			minPadding: 0,
			title: { text: '' },
			labels:{ enabled: false },
			visible: false
		},
		yAxis: {
			min: 0,
			max: 100,
			maxPadding: 0,
			lineWidth: 0,
			gridLineWidth: 0,
			minPadding: 0,
			title: { text: '' },
			labels:{ enabled: false },
			plotLines: [
				{
					color: '#FEE84E',
					dashStyle: 'dash',
					width: 1,
					value: 20,
					label: {
						text: 'low',
						style: {
							color: '#FEE84E',
							fontSize: '0.6em',
							textTransform: 'uppercase'
						}
					}
				},
				{
					color: '#fdc02f',
					dashStyle: 'dash',
					width: 1,
					value: 40,
					label: {
						text: 'mid',
						style: {
							color: '#fdc02f',
							fontSize: '0.6em',
							textTransform: 'uppercase'
						}
					}
				},
				{
					color: '#e62465',
					dashStyle: 'dash',
					width: 1,
					value: 60,
					label: {
						text: 'high',
						style: {
							color: '#e62465',
							fontSize: '0.6em',
							textTransform: 'uppercase'
						}
					}
				},
				{
					color: '#E057CD',
					dashStyle: 'dash',
					width: 1,
					value: 80,
					label: {
						text: 'extreme',
						style: {
							color: '#E057CD',
							fontSize: '0.6em',
							textTransform: 'uppercase'
						}
					}
				}
			],
		},
		tooltip: { 
			enabled: false,
			shared: true,
		},
		plotOptions: {
			series: {
				groupPadding: 0.05,
				pointPadding: 0,
				pointPlacement: 'on',
				lineWidth: 1,
				stickyTracking: false,
				animation: {
					duration: 200
				},
				states: {
					hover: {
						color: '#a4edba',
						borderColor: 'gray'
					}
				}
			},
			spline: {
				color: 'rgba(255, 255, 255, 0.8)',
				dashStyle: 'Dot',
				marker: {
					enabled: false,
					radius: 1,
					states: {
						hover: {
							radiusPlus: 0,
						}
					}
				}
			}
		},
	}

	return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}