import React from 'react';
import { Date, Dot } from '@app.elements'
import './style.scss'

export default ({date, isLive=true}) => <div data-part="map-date" data-live={!date}>
	<Dot/>
	{!date 
		? [<span key={1} data-part='title'>LIVE</span>, <span key={2} data-part='subtitle'>Auto Refresh</span>]
		: <Date timestamp={date} format={{date: 'MMMM DD YYYY', time: 'h:mma' }}/>}
</div>
