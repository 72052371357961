import moment from 'moment'
import { get, findKey }from 'lodash'
import InterfaceState, { fetchstatus, MonitorMap } from '../state'
import App from '@app';

class State extends InterfaceState{
	init(){
		this.createContext();
	}

	defaultParams(){
		return {
			dates: { 
				title: 'Last 3 hours', 
				points: '12', 
				start: moment().subtract(3, 'hours'), 
				end: moment() 
			},
			timestep: 3600,
			graph: new MonitorMap(),
			currentTime: null,
			timelines: new Set([]),
		}
	}

	hydrateEvent(contextId, eventId, callback=()=>{}){
		this.contexts[contextId].status = fetchstatus.FETCHING
		
		setTimeout( () => {
			App.socket.on(`context.event.hydrate.success.${contextId}`, data => {
				this.contexts[contextId].series = data.timeseries
				this.contexts[contextId].layers.backtrack = data.backtracks
				this.contexts[contextId].layers.observation = data.observations
				this.contexts[contextId].layers.event = data.events
				this.contexts[contextId].layers.sourceid = data.sourceid
				this.contexts[contextId].layers.iq = data.iq
				//this.contexts[contextId].layers.heatmap = data.heatmaps
				this.contexts[contextId].details = data.details
				this.contexts[contextId].status = fetchstatus.COMPLETE

				this.toggleLayer('event')

				// set pollutant on all active monitors
				data.details.activeMonitors.forEach( name => {
					this.contexts[contextId].activeParticulates.set(name, data.details.defaultparticulate)
					this.contexts[contextId].graph.add(name, data.details.defaultparticulate)
				});

				this.contexts[contextId].timelines.add('monitors')

				callback(this.contexts[contextId])

				this.update()
			})
		
			App.socket.emit('context.event.hydrate', {contextId: contextId, eventId: eventId})
		}, 1000)
	}

	toggleTimeline(name){
		this.current.timelines.toggle(name)
		this.update()
	}

	changeDates(dates){
		this.current.dates = dates
		this.hydrate(this.currentContext, this.current.dates)
		this.update()	
	}

	analyse(items, newContext=false){
		// create a context if required
		if(!this.current || newContext === true) this.createContext()

		items.forEach( (particulates, id) => {
			particulates.forEach( particulate => {
				this.current.graph.add(id, particulate)
			})
		})
		this.current.timelines.add('monitors')
		this.update()
	}

	analyseEvent(eventId){
		let id = findKey(this.contexts, context => get(context, `details.id`) === eventId )

		if(id){
			this.useContext(id)
		}else{
			let contextId = this.createContext()
			this.hydrateEvent(contextId, eventId)
		}
	}
}

const instance = new State('compare')
Object.seal(instance);

export default instance
