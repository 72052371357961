import React, { useState, useEffect } from 'react'

// static pollution rose for testing
export default () => {

	let [visible, setVisible] = useState(false)

	useEffect(() => {
		setVisible(true)
	}, [])

	return <svg data-part="pollution-rose" xmlns="http://www.w3.org/2000/svg" width="350" height="350" viewBox="0 0 350 350" data-visible={visible}>
		<g>
			<g>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 157.56686890650985 77.37769866441909 A 99.16666666666667 99.16666666666667 0 0 1 192.33550009186013 77.36031434737771 L 191.31576479233897 83.10382526812019 A 93.33333333333334 93.33333333333334 0 0 0 158.5923472061269 83.12018697827678 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 200.00273452425546 61.04398051256479 A 116.66666666666667 116.66666666666667 0 0 1 237.80118089565963 76.67847240191115 L 234.66112185087667 81.59454878181559 A 110.83333333333334 110.83333333333334 0 0 0 198.7525977980427 66.74178148693653 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 275.06289303983243 31.429747383035306 A 175 175 0 0 1 318.470117955482 74.79358676292186 L 308.9054434251166 81.4740143120604 A 163.33333333333334 163.33333333333334 0 0 0 268.3920335038436 41.001097557499605 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 307.64921431031746 90.08571414152111 A 157.5 157.5 0 0 1 328.8067957017495 141.0924846684352 L 317.4136997238421 143.60415247077333 A 145.83333333333334 145.83333333333334 0 0 0 297.8233465836273 96.37566124214918 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 272.62230133558097 157.5668689065102 A 99.16666666666667 99.16666666666667 0 0 1 272.63968565262223 192.33550009186047 L 266.89617473187974 191.31576479233928 A 93.33333333333334 93.33333333333334 0 0 0 266.8798130217233 158.59234720612722 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 252.49009325145624 192.00185947649243 A 79.33333333333334 79.33333333333334 0 0 1 241.85863876670112 217.70480300904745 L 238.90899293875844 215.82076758217772 A 75.83333333333334 75.83333333333334 0 0 0 249.07141266683317 191.25177744076484 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 231.4709660293413 214.35807126233144 A 68.83333333333334 68.83333333333334 0 0 1 214.41452253992003 231.43157972915444 L 213.74647978500613 230.4751122761179 A 67.66666666666667 67.66666666666667 0 0 0 230.51383101189484 213.6909853087326 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 212.1106878937054 232.9726195874722 A 68.83333333333334 68.83333333333334 0 0 1 189.81884003379474 242.21926626965353 L 189.56767325356094 241.0799566718628 A 67.66666666666667 67.66666666666667 0 0 0 211.4816931836426 231.9900328148032 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 187.10064393548095 242.76136210352098 A 68.83333333333334 68.83333333333334 0 0 1 162.96712346564954 242.77342886476129 L 163.1710705255538 241.62472668061278 A 67.66666666666667 67.66666666666667 0 0 0 186.89554827555753 241.61286444074943 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 160.2483866306902 242.234051497587 A 68.83333333333334 68.83333333333334 0 0 1 137.94730327156157 233.00970128287292 L 138.57531508051818 232.02648600689201 A 67.66666666666667 67.66666666666667 0 0 0 160.49841397593272 241.09449130271264 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 101.62054510412744 280.2848519191106 A 128.33333333333334 128.33333333333334 0 0 1 69.788580165983 248.48470304052853 L 108.04727828744373 221.7629928439727 A 81.66666666666667 81.66666666666667 0 0 0 128.3039832480811 241.99945122125223 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 117.02738041252768 212.11068789370523 A 68.83333333333334 68.83333333333334 0 0 1 107.78073373034641 189.81884003379454 L 108.92004332813715 189.5676732535607 A 67.66666666666667 67.66666666666667 0 0 0 118.0099671851967 211.48169318364242 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 96.90215893153511 188.94650487479132 A 79.33333333333334 79.33333333333334 0 0 1 96.88825147790232 161.13159992651106 L 100.3343580303478 161.7434411062238 A 75.83333333333334 75.83333333333334 0 0 0 100.34765191984974 188.3312178950211 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 107.76594850241307 160.24838663068994 A 68.83333333333334 68.83333333333334 0 0 1 116.99029871712722 137.94730327156137 L 117.9735139931081 138.57531508051795 A 67.66666666666667 67.66666666666667 0 0 0 108.90550869728743 160.4984139759325 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 118.52903397065896 135.6419287376682 A 68.83333333333334 68.83333333333334 0 0 1 135.5854774600803 118.56842027084531 L 136.2535202149942 119.52488772388183 A 67.66666666666667 67.66666666666667 0 0 0 119.48616898810542 136.30901469126704 Z"/>
				<path fill="rgba(230, 36, 101, 0.5)" d="M 137.889312106295 117.02738041252755 A 68.83333333333334 68.83333333333334 0 0 1 160.18115996620568 107.78073373034638 L 160.4323267464395 108.92004332813713 A 67.66666666666667 67.66666666666667 0 0 0 138.51830681635778 118.00996718519657 Z"/>
			</g>
			<g>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 158.5923472061269 83.12018697827678 A 93.33333333333334 93.33333333333334 0 0 1 191.31576479233897 83.10382526812019 L 189.2762941932966 94.59084710960516 A 81.66666666666667 81.66666666666667 0 0 0 160.64330380536106 94.60516360599217 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 198.7525977980427 66.74178148693653 A 110.83333333333334 110.83333333333334 0 0 1 234.66112185087667 81.59454878181559 L 225.2409447165277 96.34277792152892 A 93.33333333333334 93.33333333333334 0 0 0 195.00218761940437 83.83518441005182 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 268.3920335038436 41.001097557499605 A 163.33333333333334 163.33333333333334 0 0 1 308.9054434251166 81.4740143120604 L 289.77609436438564 94.83486941033749 A 140 140 0 0 0 255.05031443186593 60.14379790642823 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 297.8233465836273 96.37566124214918 A 145.83333333333334 145.83333333333334 0 0 1 317.4136997238421 143.60415247077333 L 288.93095977907365 149.88332197661867 A 116.66666666666667 116.66666666666667 0 0 0 273.2586772669018 112.10052899371934 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 266.8798130217233 158.59234720612722 A 93.33333333333334 93.33333333333334 0 0 1 266.89617473187974 191.31576479233928 L 255.40915289039478 189.27629419329685 A 81.66666666666667 81.66666666666667 0 0 0 255.39483639400788 160.6433038053613 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 249.07141266683317 191.25177744076484 A 75.83333333333334 75.83333333333334 0 0 1 238.90899293875844 215.82076758217772 L 233.99291655885395 212.68070853739482 A 70 70 0 0 0 243.3736116924614 190.00164071455214 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 230.51383101189484 213.6909853087326 A 67.66666666666667 67.66666666666667 0 0 1 213.74647978500613 230.4751122761179 L 211.74235152026444 227.60570991700837 A 64.16666666666667 64.16666666666667 0 0 0 227.64242595955545 211.6897274479361 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 211.4816931836426 231.9900328148032 A 67.66666666666667 67.66666666666667 0 0 1 189.56767325356094 241.0799566718628 L 188.8141729128595 237.66202787849056 A 64.16666666666667 64.16666666666667 0 0 0 209.59470905345418 229.04227249679613 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 186.89554827555753 241.61286444074943 A 67.66666666666667 67.66666666666667 0 0 1 163.1710705255538 241.62472668061278 L 163.7829117052665 238.1786201281673 A 64.16666666666667 64.16666666666667 0 0 0 186.2802612957873 238.1673714524348 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 160.49841397593272 241.09449130271264 A 67.66666666666667 67.66666666666667 0 0 1 138.57531508051818 232.02648600689201 L 140.4593505073879 229.07684017894934 A 64.16666666666667 64.16666666666667 0 0 0 161.24849601166034 237.67581071808956 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 128.3039832480811 241.99945122125223 A 81.66666666666667 81.66666666666667 0 0 1 108.04727828744373 221.7629928439727 L 117.61195281780891 215.08256529483376 A 70 70 0 0 0 134.97484278406952 232.42810104678762 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 118.0099671851967 211.48169318364242 A 67.66666666666667 67.66666666666667 0 0 1 108.92004332813715 189.5676732535607 L 112.33797212150938 188.8141729128593 A 64.16666666666667 64.16666666666667 0 0 0 120.95772750320377 209.594709053454 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 100.34765191984974 188.3312178950211 A 75.83333333333334 75.83333333333334 0 0 1 100.3343580303478 161.7434411062238 L 106.07786895109028 162.76317640574504 A 70 70 0 0 0 106.09014023370746 187.3057395954041 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 108.90550869728743 160.4984139759325 A 67.66666666666667 67.66666666666667 0 0 1 117.9735139931081 138.57531508051795 L 120.92315982105079 140.45935050738774 A 64.16666666666667 64.16666666666667 0 0 0 112.32418928191049 161.2484960116601 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 119.48616898810542 136.30901469126704 A 67.66666666666667 67.66666666666667 0 0 1 136.2535202149942 119.52488772388183 L 138.2576484797359 122.39429008299139 A 64.16666666666667 64.16666666666667 0 0 0 122.3575740404448 138.31027255206357 Z"/>
				<path fill="rgba(253, 192, 47, 0.5)" d="M 138.51830681635778 118.00996718519657 A 67.66666666666667 67.66666666666667 0 0 1 160.4323267464395 108.92004332813713 L 161.1858270871409 112.33797212150934 A 64.16666666666667 64.16666666666667 0 0 0 140.4052909465462 120.95772750320364 Z"/>
			</g>
			<g>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 160.64330380536106 94.60516360599217 A 81.66666666666667 81.66666666666667 0 0 1 189.2762941932966 94.59084710960516 L 185.19735299521184 117.56489079257511 A 58.33333333333334 58.33333333333334 0 0 0 164.7452170038293 117.57511686142298 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 195.00218761940437 83.83518441005182 A 93.33333333333334 93.33333333333334 0 0 1 225.2409447165277 96.34277792152892 L 206.4005904478298 125.83923620095557 A 58.33333333333334 58.33333333333334 0 0 0 187.50136726212773 118.02199025628238 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 255.05031443186593 60.14379790642823 A 140 140 0 0 1 289.77609436438564 94.83486941033749 L 222.82337265182736 141.5978622543073 A 58.33333333333334 58.33333333333334 0 0 0 208.35429767994415 127.14324912767842 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 273.2586772669018 112.10052899371934 A 116.66666666666667 116.66666666666667 0 0 1 288.93095977907365 149.88332197661867 L 231.96547988953685 162.44166098830934 A 58.33333333333334 58.33333333333334 0 0 0 224.1293386334509 143.55026449685965 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 255.39483639400788 160.6433038053613 A 81.66666666666667 81.66666666666667 0 0 1 255.40915289039478 189.27629419329685 L 232.43510920742486 185.19735299521204 A 58.33333333333334 58.33333333333334 0 0 0 232.42488313857706 164.7452170038295 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 243.3736116924614 190.00164071455214 A 70 70 0 0 1 233.99291655885395 212.68070853739482 L 224.16076379904496 206.40059044782902 A 58.33333333333334 58.33333333333334 0 0 0 231.97800974371782 187.5013672621268 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 227.64242595955545 211.6897274479361 A 64.16666666666667 64.16666666666667 0 0 1 211.74235152026444 227.60570991700837 L 208.40213774569494 222.8233726518258 A 58.33333333333334 58.33333333333334 0 0 0 222.85675087232312 208.3542976799419 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 209.59470905345418 229.04227249679613 A 64.16666666666667 64.16666666666667 0 0 1 188.8141729128595 237.66202787849056 L 187.55833901169046 231.96547988953688 A 58.33333333333334 58.33333333333334 0 0 0 206.44973550314018 224.12933863345103 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 186.2802612957873 238.1673714524348 A 64.16666666666667 64.16666666666667 0 0 1 163.7829117052665 238.1786201281673 L 164.80264700478776 232.43510920742483 A 58.33333333333334 58.33333333333334 0 0 0 185.2547829961703 232.4248831385771 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 161.24849601166034 237.67581071808956 A 64.16666666666667 64.16666666666667 0 0 1 140.4593505073879 229.07684017894934 L 143.59940955217084 224.16076379904484 A 58.33333333333334 58.33333333333334 0 0 0 162.49863273787304 231.9780097437178 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 134.97484278406952 232.42810104678762 A 70 70 0 0 1 117.61195281780891 215.08256529483376 L 127.1766273481741 208.4021377456948 A 58.33333333333334 58.33333333333334 0 0 0 141.64570232005792 222.856750872323 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 120.95772750320377 209.594709053454 A 64.16666666666667 64.16666666666667 0 0 1 112.33797212150938 188.8141729128593 L 118.03452011046306 187.5583390116903 A 58.33333333333334 58.33333333333334 0 0 0 125.87066136654887 206.44973550314 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 106.09014023370746 187.3057395954041 A 70 70 0 0 1 106.07786895109028 162.76317640574504 L 117.56489079257523 164.80264700478753 A 58.33333333333334 58.33333333333334 0 0 0 117.57511686142287 185.2547829961701 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 112.32418928191049 161.2484960116601 A 64.16666666666667 64.16666666666667 0 0 1 120.92315982105079 140.45935050738774 L 125.83923620095527 143.59940955217064 A 58.33333333333334 58.33333333333334 0 0 0 118.02199025628227 162.49863273787284 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 122.3575740404448 138.31027255206357 A 64.16666666666667 64.16666666666667 0 0 1 138.2576484797359 122.39429008299139 L 141.59786225430537 127.17662734817398 A 58.33333333333334 58.33333333333334 0 0 0 127.14324912767708 141.64570232005778 Z"/>
				<path fill="rgba(173, 218, 72, 0.5)" d="M 140.4052909465462 120.95772750320364 A 64.16666666666667 64.16666666666667 0 0 1 161.1858270871409 112.33797212150934 L 162.4416609883099 118.03452011046303 A 58.33333333333334 58.33333333333334 0 0 0 143.55026449686017 125.87066136654877 Z"/>
			</g>
		</g>
	</svg>

}