import { filter, find } from 'lodash'

// eslint-disable-next-line no-extend-native
String.prototype.sluggify = function(){
	const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;'
	const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------'
	const p = new RegExp(a.split('').join('|'), 'g')
	return this.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with ‘and’
		.replace(/[^\w\\-]+/g, '') // Remove all non-word characters
		.replace(/\\-\\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text
};

// helper function tp get all react children of a specific type
// note: type must be explicitly defined on element
export const filterReactChildren = (children, types, debug) => {

	children = Array.isArray(children) ? children : [children]

	return Object.keys(types).map( type => {
		let filterAll = types[type];
		let items = filterAll === true
			? filter(children, child => child.type.displayName === type)
			: find(children, child => child.type.displayName === type)
		
		return items
	}).filter(i=>i)
} 

// eslint-disable-next-line no-extend-native
Set.prototype.toggle = function(val){
	this.has(val) ? this.delete(val) : this.add(val)
}