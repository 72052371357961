import React from 'react';
import { get } from 'lodash'
import ParticulateGraph from '../particulategraph/'
import { Icon, Hint, Date } from '@app.elements';
import './style.scss'

const Timeline = ({name, visible, data=[], children}) => {
	return <div data-part='timeline' data-name={name} data-open={visible}>
		{ visible && [
			data.map( (item, i) => 
				<Hint key={i} style={{left: `${item.position}%`}} title={item.title} text={item.text}>
					{item.icon}
				</Hint>
			),
		]}

		{visible && children}
	</div>
}

Timeline.Monitors = ({current}) => {
	let child = current.graph.size <= 0
		? <div data-part="message">Select a monitor to analyse</div>
		: <ParticulateGraph items={current.graph.items}/>

	return <Timeline name='monitors' current={current} children={child} visible={true}/>
}

Timeline.Backtracks = ({visible, items}) => {
	let data = items.map( item => ({
		title: `Backtrack #${item.id}`,
		text: `Lat: ${get(item, 'coords.lat', 0).toFixed(4)} <br/> Lng: ${get(item, 'coords.lng', 0).toFixed(4)} <br/> Duration: ${item.duration}`,
		position: item.time,
		icon: <Icon.toll onClick={e => console.log('handle backtrack click: ' + item.id) }/>
	}))

	return <Timeline name='backtracks' visible={visible} data={data}/>
}

Timeline.Observations = ({visible, items}) => {
	let data = items.map( item => ({
		title: `Observation #${item.id}`,
		text: `Lat: ${item.coords.lat.toFixed(4)} <br/> Lng: ${item.coords.lng.toFixed(4)}`,
		position: item.time,
		icon: <Icon.eye onClick={e => console.log('handle observation click: ' + item.id) }/>
	}))

	return <Timeline name='observations' visible={visible} data={data}/>
}

Timeline.Events = ({visible, items}) => {
	let data = items.map( item => ({
		title: `Event #${item.id}`,
		text: `Lat: ${item.location.lat.toFixed(4)} <br/> Lng: ${item.location.lng.toFixed(4)}`,
		position: item.time,
		icon: <Icon.warning onClick={e => console.log('handle event click: ' + item.id) }/>
	}))

	return <Timeline name='events' visible={visible} data={data}/>
}

Timeline.Date = ({start, end}) => {
	return <div data-part='timeline' data-name={'times'}>
		<div data-part='bar'/>
		<div data-part='lines'/>
		<div data-part='dates'>
			<Date timestamp={start} format={{date: 'MMM DD', time: 'kk:mma' }}/>
			<Date timestamp={end} format={{date: 'MMM DD', time: 'kk:mma' }}/>
		</div>
	</div>
}

export default Timeline