import React, { useMemo } from 'react';
import { get } from 'lodash'
import CanvasLayer from 'react-leaflet-canvas-layer';
import './style.scss';

import mapStore from '../../store'

class Vector{
	distance = 0
	life = 100
	vx
	vy
	x
	y

	random_x(){
		var n = document.body.offsetWidth * (1 + 0.001);
		return (1 - (1 + 0.001)) + (Math.random() * n);
	}

	draw(ctx) {
		let opacity = this.life > 90 ? (((100 - this.life)/3) * 0.1) : ((this.life * 0.3) / 100)
		var gradient = ctx.createLinearGradient(this.x, this.y, this.x + 20, this.y + 16);
		gradient.addColorStop("0", `rgba(255,255,255,${opacity})`);
		gradient.addColorStop("1.0", "rgba(255,255,255,0)");

		// fill with gradient
		ctx.strokeStyle = gradient;
		ctx.lineWidth = 2;
		
		// draw path
		ctx.beginPath();
		ctx.moveTo(this.x, this.y);
		ctx.lineTo(this.x + 20, this.y + 16);
		ctx.stroke();
		ctx.closePath();
	}
}

const drawMethod = info => {
	let ctx = info.canvas.getContext('2d')
	let height = info.canvas.height = document.body.offsetHeight
	let width = info.canvas.width = document.body.offsetWidth
	let vectors = []
	let num_items = 100 // number of vectors

	// need to add a lifespan function to catch vectors after x frames
	const draw_frame = () => {
		ctx.clearRect(0, 0, width, height);
		ctx.globalAlpha = 1;

		vectors.forEach((vector, i) => {
			vector.x += vector.vx;
			vector.y += vector.vy;
			vector.distance += 1;
			vector.life = 100 - (100 / vector.maxDistance * vector.distance)

			// out of bounds or dead?
			if (vector.x < 0 || vector.y < 0 || vector.life < 10) {
				vectors.splice(i, 1);
				createVector()
			}

			vector.draw(ctx);
		});
	}

	const animate = () => {
		window.requestAnimationFrame(animate);
		draw_frame();
	}

	const createVector = random => {
		let vector = new Vector(); 
		vector.vx = -0.4;
		vector.vy = -0.3;
		vector.maxDistance = 50 + Math.round(Math.random() * 400)
		vector.x = vector.random_x()
		vector.y = Math.random() * height
		vectors.push(vector);
	}

	// create inital batch randomly
	for (var i = 0; i < num_items; i++) {
		createVector(true)
	}
	
	// start animation
	animate();
}

export default () => {
	const { state } = mapStore()
	let visible = get(state, 'map.current.options.wind')
	return useMemo(() => visible ? <CanvasLayer drawMethod={drawMethod}/> : null, [visible])
}