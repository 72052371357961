// eslint-disable-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react';
import { get } from 'lodash'
import pluralize from 'pluralize'
import { DateRange } from '@app.components';
import { Panel, Icon } from '@app.elements';
import './style.scss'

import mapStore from '@app.components/map/store'
import Timeline from './parts/timeline/'

const Tab = ({id}) => {
	const { state } = mapStore()
	let context = state.map.contexts[id]

	let tabId = get(context, 'details.id')
	let monitor_count = get(context, 'graph.size', 0)
	let particulate_count = get(context, 'graph.childrensize', 0)

	return <div data-part='tab' data-active={id === state.map.currentContext} onClick={() => state.map.useContext(id)}>
		<Icon.times onClick={e => {
			e.stopPropagation()
			state.map.removeContext(id)
		}}/>
		<span data-part="tab-id" data-is-event={!!tabId}>
			{context.status === 'fetching'
				? ''
				: tabId 
					? <React.Fragment><Icon.warning/>#{tabId}</React.Fragment>
					: `#${id.split('-')[0]}`
			}
		</span>
		<DateRange dates={context.dates}/>
		<span data-part="tab-info"> 
			{monitor_count} {pluralize('monitor', monitor_count)} | {particulate_count} {pluralize('particulate', particulate_count)}
		</span>
	</div>
}

const Analyse = ({context}) => {
	const current = context.current
	let eventId = get(current, 'details.id')

	return <article data-part='analyse' data-status={current.status}>
		<header data-part='header'>
			<span data-part='controls'>
				{/*<Button onClick={ on => context.toggleLayer('monitor') } attrs={{'data-active': current.layers.visible.has('monitor')}}><Icon.antenna/>Monitors<Dot text={current.graph.size}/></Button>*/}
				{/*<Button onClick={ on => context.toggleLayer('backtrack') } attrs={{'data-active': current.layers.visible.has('backtrack')}}><Icon.toll/>Backtracks<Dot text={current.backtracks.length}/></Button>*/}
				{/*<Button onClick={ on => context.toggleLayer('observation') } attrs={{'data-active': current.layers.visible.has('observation')}}><Icon.eye/>Observations<Dot text={current.observations.length}/></Button>*/}
				{/*<Button onClick={ on => context.toggleLayer('event') } attrs={{'data-active': current.layers.visible.has('event')}}><Icon.antenna/>Events<Dot text={current.events.length}/></Button>*/}
			</span>
			
			{!eventId && <DateRange.Picker dates={current.dates} onChange={ dates => context.changeDates(dates) }/>}
		</header>
		<div data-part='timelines'>
			<Timeline.Monitors current={current} />
			<Timeline.Backtracks visible={get(current, 'layers.visible', new Set()).has('backtrack')} items={get(current, 'layers.backtrack', [])}/>
			<Timeline.Observations visible={get(current, 'layers.visible', new Set()).has('observation')} items={get(current, 'layers.observation', [])}/>
			<Timeline.Events visible={get(current, 'layers.visible', new Set()).has('event')} items={get(current, 'layers.event', [])}/>
			<Timeline.Date {...current.dates}/>
		</div>
		<Icon.cog/>
	</article>
}


export default ({selected, event}) => {

	const { state: mapState } = mapStore()
	const [state, setState] = useState(null)

	// analyse selected monitors on mount
	useEffect(()=>{
		selected && mapState.map.analyse(selected, true)
	}, [selected])

	useEffect(()=>{
		event && mapState.map.analyseEvent(event)
	}, [event])
	
	// 
	useEffect(()=>{
		setState(mapState.map)
	}, [mapState])

	if(!state) return null;

	return <Panel className="-comparison" open={!!state.currentContext}>
		<Panel.Header>
			{Object.keys(state.contexts).map( id => <Panel.Header.Group key={id}><Tab id={id}/></Panel.Header.Group> )}
			<Icon.addcircle onClick={e => state.createContext({}, true)} attrs={{'data-empty': Object.keys(state.contexts).length <= 0}}/>
		</Panel.Header>

		{state.currentContext && 
			<Panel.Body>
				<Analyse context={state}/>
			</Panel.Body>
		}

	</Panel>
}