import React from 'react';
import { filterReactChildren } from '@app.util'
import './style.scss'

 const Select = React.memo( ({title, value, children, onChange=()=>{}}) => {

 	let [options] = filterReactChildren(children, { Option: true })
	
	return <div data-type='@e.select' >
		{title && <span data-part="title">{title}</span>}
		<select data-part='select' onChange={ e => onChange(e.target.value) } value={value}>
			{options}
		</select>
	</div>
})

Select.Option = ({label, value}) => <option value={value||label.toLowerCase()} /*selected={selected}*/>{label}</option>
Select.Option.displayName = 'Option';


export default Select