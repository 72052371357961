import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter as Router } from "react-router-dom";
import App from '@app';

// global styles
import '@global.style/reset.scss';
import '@global.style/layout.scss';
import '@global.style/typeography.scss';

ReactDOM.render(
    <AppContainer>
        <Router>
            <App />
        </Router>
    </AppContainer>, 
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept('./app/index.jsx', () => {
        const NextApp = require('./app/index.jsx').default; // eslint-disable-line global-require
        ReactDOM.render(
            <AppContainer>
                <Router>
				    <NextApp />
                </Router>
			</AppContainer>,
            document.getElementById('root')
        );
    });
}