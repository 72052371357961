import Store from '@app.util/store.v3'
import MAP_THEME from './themes/dark_custom.js';
import Leaflet from 'leaflet'

const MAP_API_KEY = process.env.REACT_APP_MAP_GOOGLE_KEY;
const MAP_MAPTYPE = process.env.REACT_APP_MAP_TYPE;
const MAP_LAT = process.env.REACT_APP_MAP_INIT_LAT;
const MAP_LNG = process.env.REACT_APP_MAP_INIT_LNG;
const MAP_ZOOM = process.env.REACT_APP_MAP_INIT_ZOOM;

const mapParams = () => ({
	map : {
		zoom : MAP_ZOOM,
		bounds: Leaflet.latLngBounds(
			Leaflet.latLng(-27.34760396316987, 153.2495018318177),
			Leaflet.latLng(-27.558292285164644, 152.93900431296407)
		),
		lat: MAP_LAT,
		lng: MAP_LNG,
		apikey: MAP_API_KEY,
		type: MAP_MAPTYPE,
		theme: MAP_THEME
	}
})

const triggers = {
	flyTo: ({state, data}) => {
		state.mapRef.flyTo(Leaflet.latLng(data.lat, data.lng), data.zoom||12)
	}
}

const setters = {
	mapRef: ({state, data}) => {
		state.mapRef = data
		return {...state}
	},
	mapState: ({state, data, set}) => {
		data && data.onUpdate(() => set('mapState', data, set) )
		state.map = data
		return {...state}
	},
	params: ({state, data}) => {
		//let size = monitorOptions.zoomMultiplier(data.zoom)
		//state.params = mapParams(data.zoom, data.bounds, monitorOptions.calculateState(data.zoom), monitorOptions.calculateSize(size))
		return {...state}
	},
	transition: ({state, data}) => {
		state.transition = data
		return {...state}
	}
}

const initialState = {
	params: mapParams(),
	transition: false,
	map: null,
	mapRef: null
}

const store = options => Store( 'map', {
	options: options,
	setters: setters,
	triggers: triggers,
	initialState: initialState,
})

export default store