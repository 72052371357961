import React, { useState, useEffect } from 'react'
import { Dot } from '@app.elements' 
import './style.scss'

export default ({name, value, thresholds, selected, onClick}) => {

	const [threshold, setThreshold] = useState(-1);

	useEffect(()=>{
		let threshold = thresholds.map( (t, i) => value < t ? i+1 : null ).filter(e=>e)[0]
		setThreshold(threshold)
	}, [value, thresholds])

	return <div data-type='@c.particulate' data-threshold={threshold} data-active={selected} onClick={onClick}>
		<Dot text={name}/>
	</div>
}