// eslint-disable-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react'
import './style.scss'

export default ({value=0, max=100, thickness=5}) => {
	const strokeDasharray = 2 * Math.PI * (100 - thickness)
	const [offsets, setOffsets] = useState({})
	
	useEffect(() => {
		setOffsets({
			track : strokeDasharray * (100 - (100 * (max * 0.01))) / 100,
			progress : strokeDasharray * (100 - (value * (max * 0.01))) / 100
		})
	}, [value])

	return <svg data-type={'@e.horseshoe-progress'} viewBox="0 0 200 200">
		<circle data-part="track" cx="100" cy="100" r={100-thickness} stroke="#444" fill='none' strokeWidth="10" style={{strokeDasharray: strokeDasharray, strokeDashoffset: offsets.track}}/>
		<circle data-part="progress" cx="100" cy="100" r={100-thickness} stroke="#666" fill='none' strokeWidth="10" style={{strokeDasharray: strokeDasharray, strokeDashoffset: offsets.progress}}/>
	</svg>
}