import React, { useState, useEffect } from 'react';
import { get } from 'lodash'
import Leaflet from 'leaflet'
import Marker from '../marker.tsx';
import { Icon, Hint } from '@app.elements'
import './style.scss';

import mapStore from '../../store'

const Observation = ({id, coords, title, info, age}) => {
	
	let [visible, setVisible] = useState(false)
	let [opacity, setOpacity] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setVisible(true)
		}, Math.floor(Math.random() * 1000) + 1  )
		
		let opacity = (100 / 45 * (45 - age)) * 0.01
		setOpacity(opacity < 0.1 ? 0.1 : opacity)
	}, [])

	return <Marker position={Leaflet.latLng(coords.lat, coords.lng)} id={id}>
		<div data-type="@e.marker.observation" data-visible={visible} style={{opacity: opacity}}>
			<Hint title={title} text={info}>
				<Icon.frown/>
			</Hint>
		</div>
	</Marker>
};

export default () => {
	const { state } = mapStore()
	let visible = get(state, 'map.current.layers.visible', new Set()).has('observation')
	return visible
		? get(state, 'map.current.layers.observation', []).map( (data, i) => <Observation key={i} {...data} />)
		: null
}