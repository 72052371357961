import InterfaceState from '../state'

class State extends InterfaceState{
	init(){
		let contextId = this.createContext({}, false);
		this.hydrateEvent(contextId, 'EF1234', context => {
			this.setGlobalParticulate('oi')
		})
	}
}

const instance = new State('monitor')
Object.seal(instance);
export default instance
