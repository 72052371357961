import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import leaflet, { LatLngExpression } from 'leaflet';
import { Marker, MarkerProps } from 'react-leaflet';

/*
 * Custom implementation of a leaflet div icon that allows a pre-created DOM element to act as the marker.
 * Heavily inspired by - https://github.com/ValentinH/Leaflet.DomMarkers
 */
class ElementMarker extends leaflet.DivIcon {
    constructor(options) {
        super(options);
    }

    public createIcon() {
        const { element } = this.options as { element: HTMLElement };

        // The Leaflet.DomMarkers implementation (see link above) calls:
        //
        //     this._setIconStyles(element, 'icon');
        //
        // But from what I can tell, that doesn't seem to be required.

        return element;
    }
}

interface IElementMarkerPortalProps {
    portalElementId: string;
    children: React.ReactNode;
}

/**
 * Container component which renders its content into a DOM element inside of a marker
 *
 * @param {string} targetElementId The DOM element id to render into
 * @param {ReactNode} children The content to be rendered
 */
const ElementMarkerPortal = ({ portalElementId, children }: IElementMarkerPortalProps) => {
    const host = document.getElementById(portalElementId);
    return ReactDOM.createPortal(children, host);
};

/**
 * Returns a ref containing a DOM node.
 *
 * @param ref The ref to assign the DOM node to. If a DOM node already exists, a new one will not be created.
 * @param id The ID to assign to the DOM node.
 */
const getOrCreateHostElement = (ref: React.MutableRefObject<HTMLDivElement>, id: string): React.RefObject<HTMLDivElement> => {
    if (ref.current === null) {
        const existingElement = document.getElementById(id);
        if (existingElement) {
            throw new Error(`Cannot create marker host; element '${id}' already exists. Markers MUST use a unique id.`);
        }

        const host = ref.current = document.createElement('div');
        host.setAttribute('id', id);

        document.body.appendChild(host);
    }

    return ref;
};

interface IDomElementMarkerProps {
    /**
     * The ID used to create the marker node. This MUST be unique.
     */
    id: string;
}

/**
 * A marker element that will render its contents into a DOM node.
 *
 * @param props The properties to use for creating the marker element.
 */
const DomElementMaker = (props: IDomElementMarkerProps & MarkerProps) => {
    const { id, ...markerProps } = props;

    // see: https://github.com/facebook/react/issues/14490#issuecomment-454973512
    const host = getOrCreateHostElement(useRef(null), id);
    const leafletIcon = new ElementMarker({ element: host.current });

    return (
        <Marker {...markerProps} icon={leafletIcon}>
            <ElementMarkerPortal portalElementId={id}>
                {props.children}
            </ElementMarkerPortal>
        </Marker>
    );
};

export default DomElementMaker;