import React, { useState, forwardRef, useImperativeHandle } from 'react'
import mapStore from '../../store'

import './style.scss'

export default forwardRef(({children}, ref) => {
	
	const { set } = mapStore()
	const [closed, setClosed] = useState(true)
	const [interfaceComponent, setInterfaceComponent] = useState(null)

	useImperativeHandle(ref, () => ({
		useInterface: (interfaceComponent, state) => {
			setClosed(true)
			set('transition', true)
			setTimeout( () => {
				setInterfaceComponent(interfaceComponent)
				set('mapState', state)
				set('transition', false)
				setClosed(false)
			}, 300)
		}
	}));

	return <div ref={ref} data-part="interface-container" data-closed={closed}>
		{interfaceComponent}
		{children}
	</div>
})
