import React from 'react';
import { get } from 'lodash'
import Leaflet from 'leaflet'
import { ImageOverlay } from 'react-leaflet';
//import Marker from '../marker.tsx';
//import { Icon } from '@app.elements'
import './style.scss';

import mapStore from '../../store'

const Backtrack = ({id, coords, image}) => {
	let bounds = Leaflet.latLngBounds(
		Leaflet.latLng(coords.ne[0], coords.ne[1]),
		Leaflet.latLng(coords.sw[0], coords.sw[1])
	)

	return <ImageOverlay url={image} bounds={bounds}/>
};

export default () => {
	const { state } = mapStore()
	let visible = get(state, 'map.current.layers.visible', new Set()).has('backtrack')

	return visible
		? get(state, 'map.current.layers.backtrack', []).map( (data, i) => <Backtrack key={i} {...data} />)
		: null
}