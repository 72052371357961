import React from 'react';
import App  from '@app'
import { WeatherWidget } from '@app.components';

import './style.scss'

let rootConfig = {
	parent: 'map',
	iconAfter: false
}

let situation = Object.assign({}, rootConfig, {routes: ['map']})
let analysis = Object.assign({}, rootConfig, {routes: ['analyse', 'observations']})
let modelling = Object.assign({}, rootConfig, {routes: ['plume', 'risk']})

export default () => <div data-part="map-nav">
	<div data-part="column" className={'left'}>
		<div data-part="section">{App.links(situation)}</div>
		<div data-part="section">{App.links(analysis)}</div>
		<div data-part="section">{App.links(modelling)}</div>
	</div>

	<div data-part="column" className={'right'}>
		<WeatherWidget/>
	</div>
	
</div>
