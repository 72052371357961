class Weather {
	
	data = null
	interface = null

	use = (config) => {
		this.interface = config.interface
		this.interface.setUnits(config.units)
		this.interface.setCoords(config.lat, config.lon)
		return this
	}

	fetch = async () => {
		if(!this.data) {
			this.data = await this.interface.fetch()
		}

		return this.data
	}
}

const instance = new Weather();
Object.seal(instance);

export default instance
