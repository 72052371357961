// eslint-disable-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { ContextMenu, Date, Icon } from '@app.elements'
import { dates as defaultDates } from '@app/config'
import './style.scss'

// range display
const DateRange = ({dates={}, format={date: 'MMM DD, YYYY'}}) => {
	return <div data-type="@c.daterange">
		<Date timestamp={dates.start} format={format}/>
		<Date timestamp={dates.end} format={format}/>
	</div>
}

const DateRangePicker = ({dates, onChange=()=>{}}) => {
	let [current, setCurrent] = useState({ 
		start: defaultDates[0].start, 
		end: defaultDates[0].end
	})

	// we need to turn the start/end functions in
	const handlePresetSelect = preset => {
		let dates = {
			...preset, 
			start: preset.start(),
			end: preset.end(),
		}
		
		handleUpdate(dates)
	}

	const handleUpdate = (dates, triggerChange=true) => {
		setCurrent(dates)
		triggerChange && onChange(dates)
	}

	useEffect(()=>{
		let newDates = get(dates, 'start') && get(dates, 'end')
			? dates 
			: defaultDates[0]

		handleUpdate(newDates, false)
	}, [dates])

	return <div data-type="@c.daterange.picker">
		<ContextMenu open>
			<ContextMenu.Trigger>
				<div>{current.title}</div>
				{/*<DateRange dates={current}/>*/}
				<Icon.arrow/>
			</ContextMenu.Trigger>
			<ContextMenu.Options>
				<ContextMenu.Options.Group title="Preset Ranges">
					{defaultDates.map( preset => {
						return <ContextMenu.Item key={preset.title}><button onClick={ e => handlePresetSelect(preset) }>{preset.title}</button></ContextMenu.Item>
					})}
				</ContextMenu.Options.Group>
			</ContextMenu.Options>
		</ContextMenu>
	</div>
}

DateRange.Picker = DateRangePicker
DateRange.Picker.displayName = 'Picker';

export default DateRange