import React from 'react'

export default ({icon, name, props}) => name && <i 
	{...props.attrs}
	data-type='@e.icon' 
	data-variation={name.toLowerCase()}
	className={props.className||''}
	style={props.style} 
	onClick={ e => props.onClick && props.onClick(e) }
	data-clickable={!!props.onClick}
	dangerouslySetInnerHTML={{__html: icon}}
	{...props.attrs}
/>