// eslint-disable-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import ioClient from 'socket.io-client';
import { get, find } from 'lodash'
import { Link } from '@app.elements' 
import config from '@app/config'
import appStore from '@app/store'

import './style.scss'

import GlobalHeader from '@app.partials/header'
import GlobalNav from '@app.partials/nav'

const App = withRouter( ({location, history}) => {
	
	let [layout, setLayout] = useState(null)
	let [breadcrumbs, setBreadcrumbs] = useState([])
	const [state, appActions] = appStore()
	
	useEffect(()=>{
		let socket = ioClient(process.env.REACT_APP_SOCKET_URL, {
			reconnection: true,
			reconnectionDelay: 100,
			reconnectionDelayMax : 1000,
			reconnectionAttempts: 5
		})

		socket.on('connect', () => {
			App.socket = socket
			appActions.trigger('socketReady', socket)
		})
	}, [])

	useEffect(()=>{
		if(location.pathname === '/') {
			history.push('/map')
		}else{
			setBreadcrumbs(App.breadCrumbs(location.pathname))
			setLayout(App.layoutName(location.pathname))
		}
	}, [location])

	return state.hydrated !== true
		? <h1>LOADING MAP</h1>
		: <div className='app' data-layout={layout}>
			<GlobalHeader breadcrumbs={breadcrumbs}/>
			<GlobalNav/>
			<Switch>
				{App.routes({routes:['map', 'reporting', 'insights', 'log']})}
			</Switch>
		</div>
})




// breadcrumbs based on current path
App.breadCrumbs = (path) => path.split('/').filter(Boolean) || []

// get data for a particular route
App.layoutName = (path) => {
	return get(find(config.routes, route => {
		if(route.path.root === path) return true;
		// check child routes
		return !!find( get(route, 'children', []), child => child.path === path );
	} ), 'layout')
}

// get data for a particular route
App.routeData = (routes=[], parent=null) => {
	let routebase = !parent
		? config.routes
		: get(config.routes, `${parent}.children`, []) 

	return routes.map( route => routebase[route]||null).filter(e=>e)
}

// build an array or route elements from route names
App.routes = ({routes, parent}) => {
	if(!routes) return null;
	let routeData = App.routeData(routes, parent)
	return routeData.map( route => {
		return <Route 
			key={route.name} 
			path={`${route.path.root}${route.path.param||''}`}
			render={() => route.component}
			exact
		/>
	})
} 

// build an array of link elements from on route names
App.links = ({routes, parent=null, iconAfter=false}) => {
	if(!routes) return null;
	let routeData = App.routeData(routes, parent)
	return routeData.map( route => {
		return <Link 
			key={route.name} 
			to={{ pathname: `${route.path.root}` }} 
			handleClick={ e => route.onClick && route.onClick(e) }
		>
			{!iconAfter && route.icon}
			{route.name}
			{iconAfter && route.icon}
		</Link>
	})
}

export default App